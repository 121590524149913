import React, {useEffect} from 'react';
import {Container, Row, Col, Button} from 'react-bootstrap/';
import { Scroller } from '../Components/Scroller';
import { Grid } from '../Components/Grid';
import { CategoriesButton } from '../Components/CategoriesButton';
import { ProductTile } from '../Components/ProductTile';
import { useLoaderData } from 'react-router-dom';

export default function HomePage() {

    var data = useLoaderData()

    var categories = ["Breeches", "Sun Shirts", "Saddles", "Saddle Pads", "Bridles", "Boots", "Show Jackets", "Jackets", "Show Shirts"]
    var recentlyViewedProducts = [
        {
            id: "7d597150-d5c2-4073-a733-c0e70a4c6a62",
            title: "Ivy Full Seat Breeches",
            brand: "PS of Sweden",
            price: "30.00",
            salePrice: "25.00",
            condition: "New"
        },
        {
            id: "7d597150-d5c2-4073-a733-c0e70a4c6a62",
            title: "Prelude Full Seat Breech",
            brand: "Ariat",
            price: "60.00",
            salePrice: "49.00",
            condition: "Used"
        },
        {
            id: "7d597150-d5c2-4073-a733-c0e70a4c6a62",
            title: "Estelle Long Sleeve Magic Shirt",
            brand: "PS of Sweden",
            price: "30.00",
            salePrice: "",
            condition: "New"
        },
        {
            id: "7d597150-d5c2-4073-a733-c0e70a4c6a62",
            title: "Lux GripTEQ Riding Pants",
            brand: "Equestly",
            price: "80.00",
            salePrice: "",
            condition: "Used"
        },
        {
            id: "7d597150-d5c2-4073-a733-c0e70a4c6a62",
            title: "Estelle Long Sleeve Magic Shirt",
            brand: "PS of Sweden",
            price: "30.00",
            salePrice: "",
            condition: "New"
        },
        {
            id: "7d597150-d5c2-4073-a733-c0e70a4c6a62",
            title: "Estelle Long Sleeve Magic Shirt",
            brand: "PS of Sweden",
            price: "30.00",
            salePrice: "",
            condition: "Used"
        },
        {
            id: "7d597150-d5c2-4073-a733-c0e70a4c6a62",
            title: "Ivy Full Seat Breeches",
            brand: "PS of Sweden",
            price: "30.00",
            salePrice: "25.00",
            condition: "New"
        },
        {
            id: "7d597150-d5c2-4073-a733-c0e70a4c6a62",
            title: "Estelle Long Sleeve Magic Shirt",
            brand: "PS of Sweden",
            price: "30.00",
            salePrice: "",
            condition: "New"
        },
        
    ]

    useEffect(() => {
        
      }, []);
    


    return(
        <Container>
            {/* <Button onClick={() => { Storage.put("test", "/TwoToned-Navy-Main.jpg").then(() => {console.log("uploaded")}) }}>
                BREAK EVERYTHING
            </Button> */}
            <Row>
                <Col>
                    <Scroller>
                        {categories.map ((category) => (
                            <CategoriesButton
                                text={category}
                                link={category} // <- add linking
                            />
                        ))}
                    </Scroller>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h2>Recently Viewed</h2>

                    <Scroller style={{paddingTop:"10px", paddingBottom:"25px"}}>
                        {recentlyViewedProducts.map ((product) => (
                            <Col xs={5} md={3} className="px-1">
                                <ProductTile
                                    product = {product}
                                />
                            </Col>
                        ))}
                    </Scroller>
                </Col>

            </Row>
            <Row>
                <Col>
                    <h2>Most Favorited Items</h2>

                    <Scroller style={{paddingTop:"10px", paddingBottom:"25px"}}>
                        {data.mostFavoritedItems.map ((product) => (
                            <Col xs={5} md={3} className="px-1">
                                <ProductTile
                                    product = {product}
                                />
                            </Col>
                        ))}
                    </Scroller>
                </Col>

            </Row>

            <Row>
                <Col>
                    <h2>Explore</h2>
                    <Grid style={{padding:"10px 15px 25px"}}>
                        {data.exploreProducts.map ((product) => (
                            <Col xs={6} md={3} className="px-1">
                                <ProductTile
                                    product = {product}
                                    isGrid={true}
                                />
                            </Col>
                        ))}
                    </Grid>
                </Col>
            </Row>
            
        </Container>
    );
}