

export function categoriesLoader(categoryID){

    const category = [{
        id: "7d597150-d5c2-4073-a733-c0e70a4c6a62",
        title: "Ivy Full Seat Breeches",
        price: "30.00",
        salePrice: "25.00",
        brand: "PS of Sweden",
        condition: "Used - Like New",
        description: "Fancy breeches - only worn once for a competition but they did not fit me right. Otherwise, a lovely breathable product.",
        mainImg: "/TwoToned-Navy-Main.jpg",
        imgs: ["/TwoToned-Navy-Main.jpg", "/TwoToned-Navy-Main.jpg", "/TwoToned-Navy-Main.jpg", "/TwoToned-Navy-Main.jpg", "/TwoToned-Navy-Main.jpg",],
        shippingOptions: {
            price:"7.00",
        },
        returnOptions: {
            accepted:true,
        },
        seller: {
            id:"1627192",
            name: "Bob's Tack",
            itemsSoldCount: 279,
            followers: 295,
            aboutDescription: "Welcome, this is our tack shop, we sell tack stuff.",
            rating: {
                average: 4.75,
                count: 279
            }
        }
    },
    {
        id: "7d597150-d5c2-4073-a733-c0e70a4c6a62",
        title: "Ivy Full Seat Breeches",
        price: "30.00",
        salePrice: "25.00",
        brand: "PS of Sweden",
        condition: "Used - Like New",
        description: "Fancy breeches - only worn once for a competition but they did not fit me right. Otherwise, a lovely breathable product.",
        mainImg: "/TwoToned-Navy-Main.jpg",
        imgs: ["/TwoToned-Navy-Main.jpg", "/TwoToned-Navy-Main.jpg", "/TwoToned-Navy-Main.jpg", "/TwoToned-Navy-Main.jpg", "/TwoToned-Navy-Main.jpg",],
        shippingOptions: {
            price:"7.00",
        },
        returnOptions: {
            accepted:true,
        },
        seller: {
            id:"1627192",
            name: "Bob's Tack",
            itemsSoldCount: 279,
            followers: 295,
            aboutDescription: "Welcome, this is our tack shop, we sell tack stuff.",
            rating: {
                average: 4.75,
                count: 279
            }
        }
    },
    {
        id: "7d597150-d5c2-4073-a733-c0e70a4c6a62",
        title: "Ivy Full Seat Breeches",
        price: "30.00",
        salePrice: "25.00",
        brand: "PS of Sweden",
        condition: "Used - Like New",
        description: "Fancy breeches - only worn once for a competition but they did not fit me right. Otherwise, a lovely breathable product.",
        mainImg: "/TwoToned-Navy-Main.jpg",
        imgs: ["/TwoToned-Navy-Main.jpg", "/TwoToned-Navy-Main.jpg", "/TwoToned-Navy-Main.jpg", "/TwoToned-Navy-Main.jpg", "/TwoToned-Navy-Main.jpg",],
        shippingOptions: {
            price:"7.00",
        },
        returnOptions: {
            accepted:true,
        },
        seller: {
            id:"1627192",
            name: "Bob's Tack",
            itemsSoldCount: 279,
            followers: 295,
            aboutDescription: "Welcome, this is our tack shop, we sell tack stuff.",
            rating: {
                average: 4.75,
                count: 279
            }
        }
    }
    ,{
        id: "7d597150-d5c2-4073-a733-c0e70a4c6a62",
        title: "Ivy Full Seat Breeches",
        price: "30.00",
        salePrice: "25.00",
        brand: "PS of Sweden",
        condition: "Used - Like New",
        description: "Fancy breeches - only worn once for a competition but they did not fit me right. Otherwise, a lovely breathable product.",
        mainImg: "/TwoToned-Navy-Main.jpg",
        imgs: ["/TwoToned-Navy-Main.jpg", "/TwoToned-Navy-Main.jpg", "/TwoToned-Navy-Main.jpg", "/TwoToned-Navy-Main.jpg", "/TwoToned-Navy-Main.jpg",],
        shippingOptions: {
            price:"7.00",
        },
        returnOptions: {
            accepted:true,
        },
        seller: {
            id:"1627192",
            name: "Bob's Tack",
            itemsSoldCount: 279,
            followers: 295,
            aboutDescription: "Welcome, this is our tack shop, we sell tack stuff.",
            rating: {
                average: 4.75,
                count: 279
            }
        }
    }
    ,{
        id: "7d597150-d5c2-4073-a733-c0e70a4c6a62",
        title: "Ivy Full Seat Breeches",
        price: "30.00",
        salePrice: "25.00",
        brand: "PS of Sweden",
        condition: "Used - Like New",
        description: "Fancy breeches - only worn once for a competition but they did not fit me right. Otherwise, a lovely breathable product.",
        mainImg: "/TwoToned-Navy-Main.jpg",
        imgs: ["/TwoToned-Navy-Main.jpg", "/TwoToned-Navy-Main.jpg", "/TwoToned-Navy-Main.jpg", "/TwoToned-Navy-Main.jpg", "/TwoToned-Navy-Main.jpg",],
        shippingOptions: {
            price:"7.00",
        },
        returnOptions: {
            accepted:true,
        },
        seller: {
            id:"1627192",
            name: "Bob's Tack",
            itemsSoldCount: 279,
            followers: 295,
            aboutDescription: "Welcome, this is our tack shop, we sell tack stuff.",
            rating: {
                average: 4.75,
                count: 279
            }
        }
    },
    {
        id: "7d597150-d5c2-4073-a733-c0e70a4c6a62",
        title: "Ivy Full Seat Breeches",
        price: "30.00",
        salePrice: "25.00",
        brand: "PS of Sweden",
        condition: "Used - Like New",
        description: "Fancy breeches - only worn once for a competition but they did not fit me right. Otherwise, a lovely breathable product.",
        mainImg: "/TwoToned-Navy-Main.jpg",
        imgs: ["/TwoToned-Navy-Main.jpg", "/TwoToned-Navy-Main.jpg", "/TwoToned-Navy-Main.jpg", "/TwoToned-Navy-Main.jpg", "/TwoToned-Navy-Main.jpg",],
        shippingOptions: {
            price:"7.00",
        },
        returnOptions: {
            accepted:true,
        },
        seller: {
            id:"1627192",
            name: "Bob's Tack",
            itemsSoldCount: 279,
            followers: 295,
            aboutDescription: "Welcome, this is our tack shop, we sell tack stuff.",
            rating: {
                average: 4.75,
                count: 279
            }
        }
    }

    ]
    // <-- will be turned into a query for category

    console.log("getting info for category:" + categoryID);

    return category
}