import { PortableWifiOffSharp } from '@mui/icons-material';
import React from 'react';
import { Link} from "react-router-dom";
import { Button } from './Button';
import { ProductPrice } from './Formatter';
import { CartProductWrapper } from '../Wrappers/RelationWrappers';

export function CartTile(props){
    // console.log(props)
    // console.log(props.product.images[props.product.mainImage])

    return(
        <div className={`cartTile`}>
            <div className="imgSection">
                <img src={props.product.images[props.product.mainImage]}/>
            </div>

            <div className="productTextSection">
                <div className="textSectionHeader">
                    <div>
                        <h5 className="brand">{props.product.brand}</h5>
                        <Link to={`/product/${props.product.id}`}><h4 className="title">{props.product.title}</h4> </Link>
                        <h5 className="condition">{props.product.condition}</h5>
                    </div>
                    <ProductPrice product={props.product}/>
                </div>
                <div className="sellerSection">
                    <h5 className="seller">Seller: {props.product.soldBy.name}</h5>
                    {props.actionButtons != false &&
                        <div className="buttonSection">
                            <Button smallText > 
                                save for later
                            </Button>
                            <CartProductWrapper
                                userId={props.userId}
                                productId={props.product.id}
                                relationId={props.relationId}
                                render={({deleteRelation}) => (
                                    <Button variant smallText onClick={async () => {
                                        await deleteRelation()
                                        if (props.onRemove) {
                                            props.onRemove()
                                        }
                                    }}> 
                                        remove
                                    </Button>
                                )}
                            />
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}