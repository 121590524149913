import { redirect } from "react-router-dom";
import { getUserOrNull } from "../Helpers";
import { API } from "aws-amplify";

const userQuery = `
query GetUser($id: ID!) {
    getUser(id: $id) {
        id
        name
        image
        stripeAccountId
    }
}
`

export async function profileLoader() {
    const user = await getUserOrNull()
    if (user == null) {
        return redirect("/auth/profile")
    }
    const userData = await API.graphql({
        query: userQuery,
        variables: {
            id: user.attributes.sub
        }
    })
    const stripeId = userData.data.getUser.stripeAccountId
    var stripeUser = null
    if (stripeId != null) {
        stripeUser = await API.post("tackswapGateway", "/stripe/getAccount")
    }
    return { user: userData.data.getUser, stripeUser }
}