import '../App.scss';
import { React, useState, useEffect } from "react";
import {useLoaderData} from "react-router-dom";
import {Container, Row, Col} from 'react-bootstrap/';
import { NavBar } from '../Components/NavBar';
import { Button } from '../Components/Button';
import {CartTile} from '../Components/CartTile';
import { Scroller } from '../Components/Scroller';
import { ProductTile } from '../Components/ProductTile';
import { FormatPrice } from '../Components/Formatter';
import { Link } from 'react-router-dom';

export default function CartView(){

    const { cart, userId } = useLoaderData();

    const [items, setItems] = useState(cart.items)

    const itemCount = items.length
    const subtotal = items.reduce((a, b) => a + b.product.price, 0)

    var relatedProducts = [
        {
            id: "7d597150-d5c2-4073-a733-c0e70a4c6a62",
            title: "Ivy Full Seat Breeches",
            brand: "PS of Sweden",
            price: "30.00",
            salePrice: "25.00",
            condition: "New",
        },
        {
            id: "7d597150-d5c2-4073-a733-c0e70a4c6a62",
            title: "Prelude Full Seat Breech",
            brand: "Ariat",
            price: "60.00",
            salePrice: "49.00",
            condition: "Used",
            seller:{
                name: "Bob's Tack"
            }
        },
        {
            id: "7d597150-d5c2-4073-a733-c0e70a4c6a62",
            title: "Estelle Long Sleeve Magic Shirt",
            brand: "PS of Sweden",
            price: "30.00",
            salePrice: "",
            condition: "New",
            seller: {
                name: "Bob's Tack"
            }
        },
        {
            id: "7d597150-d5c2-4073-a733-c0e70a4c6a62",
            title: "Estelle Long Sleeve Magic Shirt",
            brand: "PS of Sweden",
            price: "30.00",
            salePrice: "",
            condition: "New",
            seller: {
                name: "Bob's Tack"
            }
        },
        {
            id: "7d597150-d5c2-4073-a733-c0e70a4c6a62",
            title: "Estelle Long Sleeve Magic Shirt",
            brand: "PS of Sweden",
            price: "30.00",
            salePrice: "",
            condition: "New",
        },
        {
            id: "7d597150-d5c2-4073-a733-c0e70a4c6a62",
            title: "Estelle Long Sleeve Magic Shirt",
            brand: "PS of Sweden",
            price: "30.00",
            salePrice: "",
            condition: "Used",
        },
        {
            id: "7d597150-d5c2-4073-a733-c0e70a4c6a62",
            title: "Ivy Full Seat Breeches",
            brand: "PS of Sweden",
            price: "30.00",
            salePrice: "25.00",
            condition: "New",
        },
        {
            id: "7d597150-d5c2-4073-a733-c0e70a4c6a62",
            title: "Estelle Long Sleeve Magic Shirt",
            brand: "PS of Sweden",
            price: "30.00",
            salePrice: "",
            condition: "New",
        },
        
    ]
    useEffect(() => {
        console.log(cart)
    }, [])

    return(
        <>
            <NavBar
                showProfile={true}
                showSidebar={true}
                showSearchIcon={true}
            />
            <Container>
                <Row className="py-4">
                    <Col xs={12}>
                        <h1>Shopping cart</h1>
                    </Col>
                
                    {/* <Col xs={12} className="cartSubtotal d-sm-none">
                        <h6>Subtotal {`(${itemCount} items)`}</h6>
                        <h6>{FormatPrice(subtotal)}</h6>
                    </Col> */}
                </Row>
                <Row>
                    <Col xs={12} sm={12} md={7} lg={8} className="cartItemsSection">
                        {items.length != 0 ?
                        <>
                            {items.map ((relation) => (
                                <Col className="px-1">
                                    <CartTile
                                        userId = {userId}
                                        relationId = {relation.id}
                                        product = {relation.product}
                                        onRemove = {() => {
                                            setItems(items.filter((item) => item.id != relation.id))
                                        }}
                                    />
                                </Col>
                            ))}
                        </>
                        :
                        <Col xs={12} className="cartEmpty">
                            <h2>Your cart is empty</h2>
                            <p>Looks like you haven't added anything to your cart yet</p>
                            <Link to="/">
                                <Button>
                                    Continue Shopping
                                </Button>
                            </Link>
                        </Col>
                    }
                    </Col>
                
                    <Col xs={12} sm={12} md={5} lg={4} className="cartCheckoutSection">
                        {items.length != 0 &&
                        <>
                            <Col xs={12}>
                                <Button checkout>
                                    Go to Checkout
                                </Button>
                            </Col>

                            <Col xs={12} className="cartSubtotal second">
                                <h6>{`${itemCount} item${itemCount > 1 ? "s":""}`}</h6>
                                <h6>{FormatPrice(subtotal)}</h6>
                            </Col>  

                            <Col xs={12} className="cartSubtotal third">
                                <h6>Shipping</h6>
                                <h6>Calculated at checkout</h6>
                            </Col>  
                            <Col xs={12} className="cartSubtotal final d-sm-flex">
                                <h6>Subtotal {`(${itemCount} items)`}</h6>
                                <h6>{FormatPrice(subtotal)}</h6>
                            </Col>
                        </>
                        }
                    </Col>
                </Row>
                <Row>
                    <Col className="py-4">
                        <h2>Related Products</h2>

                        <Scroller style={{paddingTop:"10px", paddingBottom:"25px"}}>
                            {relatedProducts.map ((product) => (
                                <Col xs={5} md={3} className="px-1">
                                    <ProductTile
                                        product = {product}
                                    />
                                </Col>

                            ))}
                        </Scroller>
                    </Col>
                </Row>
            </Container>
        </>
    );
}
