import React from 'react';
import { Link} from "react-router-dom";

export function CollectionTile(props){
    return(

        // <Col xs={5} sm>
        <Link to={`/collection/${props.collection.id}`}>
            <div className={`collectionTile ${props.isGrid && "isGrid"}`}>
                <div className="imgSection">
                    <img src="/TwoToned-Navy-Main.jpg"/>
                    {/* <img src="/image-placeholder.png"/> */}
                    
                </div>
                <div className="collectionTextSection">

                    {/* <h4 className="title">{props.collection.title}</h4>  */}
                    <h3 className="title">Collection Name</h3> 
                    
                    
                </div>
            </div>
        </Link>
        // </Col>

    );
}