import { API, graphqlOperation } from "aws-amplify";
import { useEffect, useState } from "react";
import { getUserOrNull } from "../Helpers";

const getUserCartQuery = `
query GetUserCart($userId: ID!) {
    getUser(id: $userId) {
        cart {
            items {
                id
                productId
            }
        }
    }
}
`

export function CartItemCounter({ render }) {

    const [userExists, setUserExists] = useState(false)
    const [cartItemCount, setCartItemCount] = useState(0);
    const [subscriptions, setSubscriptions] = useState({
        onCreate: API.graphql(graphqlOperation(`
            subscription OnPutProductInCart {
                onCreateCartProduct {
                    id
                }
            }
        `)),
        onDelete: API.graphql(graphqlOperation(`
            subscription OnRemoveProductFromCart {
                onDeleteCartProduct {
                    id
                }
            }
        `))
    })

    useEffect(() => {
        (async () => {
            const user = await getUserOrNull()
            if (user != null) {
                const result = await API.graphql(graphqlOperation(getUserCartQuery, { userId: user.attributes.sub }))
                const count = result.data.getUser.cart?.items?.length
                if (count != null) {
                    setUserExists(true)
                    setCartItemCount(count)
                }
            }
        })()
    }, [])

    useEffect(() => {
        if (userExists) {
            const h1 = subscriptions.onCreate.subscribe({
                next: ({ value }) => {
                    setCartItemCount(c => c + 1)
                },
                error: (error) => {
                    console.error(error)
                }
            })
            const h2 = subscriptions.onDelete.subscribe({
                next: ({ value }) => {
                    setCartItemCount(c => c - 1)
                },
                error: (error) => {
                    console.error(error)
                }
            })
            return () => {
                h1.unsubscribe()
                h2.unsubscribe()
            }
        }
    }, [subscriptions, userExists])

    return userExists && render(cartItemCount)
}