import { API, graphqlOperation } from 'aws-amplify';
import { redirect } from 'react-router-dom';
import { getUserOrNull } from '../Helpers';

const ordersQuery = `
query ListOrders {
    listOrders {
        items {
            id
            status
            total
            createdAt
            product {
                items {
                    product {
                        id
                    }
                }
            }
        }
    }
}
`

export async function ordersLoader() {
    const user = await getUserOrNull()
    if (user == null) {
        return redirect("/auth/profile")
    }
    const result = await API.graphql(graphqlOperation(
        ordersQuery
    ))
    const orders = result.data.listOrders.items
    if (orders == null) {
        return redirect("/notfound")
    }
    return orders

    // return orders
    // const orders = [{
    //     id: "7d597150-d5c2-4073-a733-c0e70a4c6a62",
    //     placedTime: "July 29, 2023",
    //     shippedTime: "",
    //     total: "45.00",
    //     products: ["1244312", "123312", "12312312"],
    //     shippingOptions: {
    //         price: "7.00",
    //     },
    //     returnOptions: {
    //         accepted: true,
    //     },
    //     seller: {
    //         id: "1627192",
    //         name: "Bob's Tack",
    //         itemsSoldCount: 279,
    //         followers: 295,
    //         aboutDescription: "Welcome, this is our tack shop, we sell tack stuff.",
    //         rating: {
    //             average: 4.75,
    //             count: 279
    //         }
    //     }
    // },
    // {
    //     id: "17844262",
    //     placedTime: "July 10, 2023",
    //     shippedTime: "",
    //     total: "279.00",
    //     products: ["1244312", "123312", "12312312"],
    //     shippingOptions: {
    //         price: "7.00",
    //     },
    //     returnOptions: {
    //         accepted: true,
    //     },
    //     seller: {
    //         id: "1627192",
    //         name: "Bob's Tack",
    //         itemsSoldCount: 279,
    //         followers: 295,
    //         aboutDescription: "Welcome, this is our tack shop, we sell tack stuff.",
    //         rating: {
    //             average: 4.75,
    //             count: 279
    //         }
    //     }
    // },
    // {
    //     id: "178126262",
    //     placedTime: "June 1, 2023",
    //     shippedTime: "",
    //     total: "30.00",
    //     products: ["1244312", "123312", "12312312"],
    //     shippingOptions: {
    //         price: "7.00",
    //     },
    //     returnOptions: {
    //         accepted: true,
    //     },
    //     seller: {
    //         id: "1627192",
    //         name: "Bob's Tack",
    //         itemsSoldCount: 279,
    //         followers: 295,
    //         aboutDescription: "Welcome, this is our tack shop, we sell tack stuff.",
    //         rating: {
    //             average: 4.75,
    //             count: 279
    //         }
    //     }
    // }

    // ]
    // return orders
}