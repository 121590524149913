import React from 'react';
import {Container, Row, Col, Stack} from 'react-bootstrap/';
import { Scroller } from '../../Components/Scroller';
import {useLoaderData, Link } from "react-router-dom";

import { CircleButton } from '../../Components/CircleButton';
import { ProductTile } from '../../Components/ProductTile';
import { CollectionTile } from '../../Components/CollectionTile';

import {AiOutlineHeart, AiOutlineMail, AiOutlineThunderbolt, AiFillStar} from "react-icons/ai";
import {BiSolidImageAdd, BiUserCircle} from 'react-icons/bi';
import {FiPackage} from 'react-icons/fi';
import {MdOutlineSell} from 'react-icons/md';
import {RiCustomerService2Fill} from 'react-icons/ri';
import { CircularProgress, Alert } from '@mui/material';

import './Profile.scss';
import {ProfileMenuButton} from '../../Components/ProfileMenuButton';
import { Button } from '../../Components/Button';

import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';

export default function UserView(){

    const navigate = useNavigate();

    async function signOut() {
        await Auth.signOut()
        navigate("/auth");
    }

    const [isProcessing, setIsProcessing] = React.useState(false);


    var recentlyViewedProducts = [
        {
            id: "7d597150-d5c2-4073-a733-c0e70a4c6a62",
            title: "Ivy Full Seat Breeches",
            brand: "PS of Sweden",
            price: "30.00",
            salePrice: "25.00",
            condition: "New"
        },
        {
            id: "7d597150-d5c2-4073-a733-c0e70a4c6a62",
            title: "Prelude Full Seat Breech",
            brand: "Ariat",
            price: "60.00",
            salePrice: "49.00",
            condition: "Used"
        },
        {
            id: "7d597150-d5c2-4073-a733-c0e70a4c6a62",
            title: "Estelle Long Sleeve Magic Shirt",
            brand: "PS of Sweden",
            price: "30.00",
            salePrice: "",
            condition: "New"
        },
        {
            id: "7d597150-d5c2-4073-a733-c0e70a4c6a62",
            title: "Lux GripTEQ Riding Pants",
            brand: "Equestly",
            price: "80.00",
            salePrice: "",
            condition: "Used"
        },
        {
            id: "7d597150-d5c2-4073-a733-c0e70a4c6a62",
            title: "Estelle Long Sleeve Magic Shirt",
            brand: "PS of Sweden",
            price: "30.00",
            salePrice: "",
            condition: "New"
        },
        {
            id: "7d597150-d5c2-4073-a733-c0e70a4c6a62",
            title: "Estelle Long Sleeve Magic Shirt",
            brand: "PS of Sweden",
            price: "30.00",
            salePrice: "",
            condition: "Used"
        },
        {
            id: "7d597150-d5c2-4073-a733-c0e70a4c6a62",
            title: "Ivy Full Seat Breeches",
            brand: "PS of Sweden",
            price: "30.00",
            salePrice: "25.00",
            condition: "New"
        },
        {
            id: "7d597150-d5c2-4073-a733-c0e70a4c6a62",
            title: "Estelle Long Sleeve Magic Shirt",
            brand: "PS of Sweden",
            price: "30.00",
            salePrice: "",
            condition: "New"
        },
        
    ]

    const { user, stripeUser } = useLoaderData();
    console.log(stripeUser)

    return(
        <Container>
            { stripeUser?.charges_enabled == false &&
                <Alert severity="warning">
                    Your account is not yet ready to recieve payments. <Link to="/profile/onboardseller/continue">Click here to set up your account.</Link>
                </Alert>
            }
            <Row>
                <Col>
                    <h2>My TackSwap</h2>
                    <p>{user.name}</p>
                </Col>
            </Row>
            <Row>
                <Col xs={12} sm={4}>
                    <div className="profileMainHeader">
                        <h1>$ 20</h1>
                        <h2>TackBucks</h2>
                    </div>
                </Col>
            </Row>
            <Row className="px-1">
                <Col xs={6} sm={4} className="px-2">
                    <ProfileMenuButton
                        text="Orders"
                        icon={<FiPackage/>}
                        to="/profile/orders/"
                    />
                </Col>
                <Col xs={6} sm={4} className="px-2">
                    <ProfileMenuButton
                        text="Favorited"
                        icon={<AiOutlineHeart/>}
                        to="/profile/favorited/"
                    />
                </Col>
                <Col xs={6} sm={4} className="px-2">
                    <ProfileMenuButton
                        text="Messages"
                        icon={<AiOutlineMail/>}
                        to="/profile/messages/"
                    />
                </Col>
            
                <Col xs={6} sm={4} className="px-2">
                    <ProfileMenuButton
                        text="Account"
                        icon={<BiUserCircle/>}
                    />
                </Col>

                <Col xs={6} sm={4} className="px-2">
                    <ProfileMenuButton
                        text="Sell"
                        icon={<MdOutlineSell/>}
                        to={
                            stripeUser == null && "/profile/onboardseller"
                            || stripeUser?.charges_enabled == false && "/profile/onboardseller/continue"
                            || "/profile/sell"
                        }
                    />
                </Col>

                <Col xs={6} sm={4} className="px-2">
                    <ProfileMenuButton
                        text="Support"
                        icon={<RiCustomerService2Fill/>}
                    />
                </Col>

                <Col xs={12} className="py-4">
                    <Button onClick={signOut} isProcessing={isProcessing} setIsProcessing={setIsProcessing}>
                        {isProcessing ? <CircularProgress /> : "Sign Out"}
                    </Button>
                </Col>
            </Row>
            <Row className="py-4">
                <Col>
                    <h2>Recently Viewed</h2>

                    <Scroller style={{paddingTop:"10px", paddingBottom:"25px"}}>
                        {recentlyViewedProducts.map ((product) => (
                            <Col xs={5} md={3} className="px-1">
                                <ProductTile
                                    product = {product}
                                />
                            </Col>
                        ))}
                    </Scroller>
                </Col>

            </Row>
        </Container>
    );
}