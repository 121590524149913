import React, { useState } from "react";
import { TextField } from "@mui/material";
import { InputAdornment } from "@mui/material";


const priceRegex = /^[0-9,]*\.?\d{0,2}$/

function priceToInt(value) {
    if (value == "") {
        return 0
    }
    const raw = value.replaceAll(",", "")
    if (raw.includes(".")) {
        const [dollars, cents] = raw.split(".")
        var amount = parseInt(dollars) * 100
        if (cents != "") {
            amount += parseInt(cents.padEnd(2, "0"))
        }
        return amount
    } else {
        return parseInt(raw) * 100
    }
}

function formatPrice(value) {
    const rawValue = value.replaceAll(",", "")
    return rawValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

function centsToPrice(value) {
    const dollars = Math.floor(value / 100)
    const cents = value % 100
    return formatPrice(dollars + "." + cents.toString().padStart(2, "0"))
}

export function PriceInput(props) {

    const { value, setValue, ...other } = props
    const [innerValue, setInnerValue] = useState(centsToPrice(value)) // Prettified value

    return (
        <TextField
            {...other}
            value={innerValue}
            InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>
            }}
            onChange={(event) => {
                if (!priceRegex.test(event.target.value)) {
                    return
                }
                const newValue = priceToInt(event.target.value)
                if (isNaN(newValue)) {
                    return
                }
                setValue(newValue)
                setInnerValue(formatPrice(event.target.value))
            }}
            onBlur={(event) => {
                setInnerValue(centsToPrice(value))
            }}
        />
    )
}