import React from 'react';
import { Link } from "react-router-dom";


export function ProfileMenuButton(props){
    return(
        <Link to={props.to}>
            <div className="profileMenuButton">
                <span>{props.icon}</span>
                <h3>{props.text}</h3>
            </div>
        </Link>
    );
}