import './App.scss';
import { Outlet, useLoaderData } from "react-router-dom";
import { NavBar } from './Components/NavBar';
import { ScrollToTop } from './Components/ScrollToTop';


export default function Main() {

    return (
        <>
            <NavBar
                showProfile={true}
                showCart={true}
                showSidebar={true}
                showSearch={true}
            />
            <ScrollToTop />
            <Outlet />
        </>
    );
}
