import { API, graphqlOperation } from 'aws-amplify';
import { redirect } from 'react-router-dom';
import { getUserOrNull, productTileFragment } from '../Helpers';

const orderQuery = `
query GetOrder($id: ID!) {
    getOrder(id: $id) {
        id
        status
        total
        createdAt
        product {
            items {
                ${productTileFragment}
            }
        }
    }
}
`

export async function orderLoader(orderID) {

    const user = await getUserOrNull()
    if (user == null) {
        return redirect("/auth/profile")
    }
    const result = await API.graphql(graphqlOperation(
        orderQuery,
        { id: orderID }
    ))
    const order = result.data.getOrder
    if (order === null) {
        return redirect("/notfound")
    }
    return order

    // const order = {
    //     id: "7d597150-d5c2-4073-a733-c0e70a4c6a62",
    //     placedTime: "July 29, 2023",
    //     shippedTime: "",
    //     total: "45.00",
    //     status: "Shipped",
    //     products: [
    //         {
    //             id: "7d597150-d5c2-4073-a733-c0e70a4c6a62",
    //             title: "Ivy Full Seat Breeches",
    //             brand: "PS of Sweden",
    //             price: "30.00",
    //             salePrice: "25.00",
    //             condition: "New",
    //             seller: {
    //                 name: "Bob's Tack"
    //             },
    //         },
    //         {
    //             id: "7d597150-d5c2-4073-a733-c0e70a4c6a62",
    //             title: "Prelude Full Seat Breech",
    //             brand: "Ariat",
    //             price: "60.00",
    //             salePrice: "49.00",
    //             condition: "Used",
    //             seller: {
    //                 name: "Bob's Tack"
    //             }
    //         },
    //         {
    //             id: "7d597150-d5c2-4073-a733-c0e70a4c6a62",
    //             title: "Estelle Long Sleeve Magic Shirt",
    //             brand: "PS of Sweden",
    //             price: "30.00",
    //             salePrice: "",
    //             condition: "New",
    //             seller: {
    //                 name: "Bob's Tack"
    //             }
    //         },
    //     ],
    //     shippingOptions: {
    //         price: "7.00",
    //     },
    //     returnOptions: {
    //         accepted: true,
    //     },
    //     seller: {
    //         id: "1627192",
    //         name: "Bob's Tack",
    //         itemsSoldCount: 279,
    //         followers: 295,
    //         aboutDescription: "Welcome, this is our tack shop, we sell tack stuff.",
    //         rating: {
    //             average: 4.75,
    //             count: 279
    //         }
    //     }
    // }
    // return order
}