export var allCategories = [
    "Breeches",
    "Belts",
    "Bridles",
    "Boots",
    "Jackets",
    "Sun Shirts",
    "Saddles",
    "Saddle Pads",
    "Show Jackets",
    "Show Shirts"
]