import React, { useEffect, useState } from "react";
import { NavBar } from "../../Components/NavBar";
import { Container, Row, Col } from "react-bootstrap/";
import { Button } from "../../Components/Button";
import { Link, useNavigate } from "react-router-dom";
import { API } from "aws-amplify";
import { CircularProgress } from "@mui/material";

export default function SellSetup() {

    const [step, setStep] = useState(1)

    return (
        <div className="setupView">
            <NavBar />
            <Container>
                <Row>
                    <Col xs={12} className="pt-4">
                        <h1>Seller Onboarding</h1>
                        <p>Let"s get you set up to sell on TackSwap!</p>
                    </Col>
                </Row>
            </Container>
            {step === 1 ? <SetupStepOne /> : null}
        </div>
    );
}

function SetupStepOne() {
    // Step One presumes that the user already has an account. Highlighted in Project Architecture.

    const [isProcessing, setIsProcessing] = useState(false)
    const navigate = useNavigate()

    return (
        <Container className="setUpStep px-4" id="setupStepOne">
            <Row className="setUpStepContent">
                <Col xs={12} className="py-4">
                    <h4>Step 1: Confirm your location.</h4>
                    <p>TackSwap is only available in the <strong>United States</strong> for Sellers at this time. Please confirm below that you are located in the <strong>United States</strong>.</p>
                </Col>
                <Col xs={12} className="py-4">
                    <Button variant onClick={async () => {
                        const link = await API.post("tackswapGateway", "/stripe/createAccount")
                        window.location.href = link.url
                    }} isProcessing={isProcessing} setIsProcessing={setIsProcessing}>
                        { isProcessing ? <CircularProgress /> : <p>I confirm, I am located in the United States.</p> }
                    </Button>
                </Col>
            </Row>
        </Container>
    )
}