import { Auth, API } from "aws-amplify";

export async function graphqlOrRedirect(query, navigate, location) {
    try {
        return await API.graphql(query)
    } catch (error) {
        if (error.message == "No current user") {
            navigate("/auth" + location.pathname)
            return null
        }
        throw error
    }
}

export async function getUserOrNull() {
    try {
        const usr = Auth.currentAuthenticatedUser()
        return await usr
    } catch (error) {
        if (error === "The user is not authenticated") {
            return null
        } else {
            throw error
        }
    }
}

export const productTileFragment = `
product {
    id
    brand
    title
    condition
    price
    salePrice
    images
    mainImage
    soldBy {
        id
        name
    }
}
`