import { getImageListItemBarUtilityClass } from '@mui/material';
import React, {useEffect} from 'react';
import { Link } from "react-router-dom";
import { ProductPrice } from './Formatter';

export function OrderTile(props){

    return(

        // <Col xs={5} sm>
        <Link to={`/order/${props.order.id}`}>
            <div className={`orderTile`}>
                <div className="orderTextSection">
                    <p className="id">#{props.order.id.slice(-5)}</p>
                    <h4 className="title">{props.order.createdAt}</h4> 
                    <p className="itemCount">{props.order.product.items.length} Items</p>
                </div>
                <ProductPrice product={props.order.product} />
            </div>
        </Link>
        // </Col>

    );
}