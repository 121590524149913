import React from "react";
import { Container, Row, Col} from "react-bootstrap";
import {useLoaderData, Link} from "react-router-dom";
import { OrderTile } from "../../Components/OrderTile";
import { Scroller } from '../../Components/Scroller';
import { ProductTile } from '../../Components/ProductTile';

export default function Favorited(){

    const favorited = useLoaderData();

    console.log(favorited);

    return(
        <Container>
            <Row>
                <Col>
                    <div className="">
                        <h1>Favorited Products</h1>

                        <Scroller style={{paddingTop:"10px", paddingBottom:"25px"}}>
                            {favorited.favoriteProducts.items.map ((product) => (
                                <Col xs={5} md={3} className="px-1">
                                    <ProductTile
                                        product = {product.product}
                                    />
                                </Col>
                            ))}
                        </Scroller>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="">
                        <h1>Favorited Sellers</h1>
                        
                        <Scroller style={{paddingTop:"10px", paddingBottom:"25px"}}>
                            {favorited.favoriteUsers.items.map ((product) => (
                                <Col xs={5} md={3} className="px-1">
                                    <ProductTile
                                        product = {product}
                                    />
                                </Col>
                            ))}
                        </Scroller>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}