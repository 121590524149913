import React, {useState} from "react"
import {Container, Row, Col} from 'react-bootstrap/';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Link, useNavigation } from "react-router-dom";
import Nav from 'react-bootstrap/Nav';
import {AiOutlineMenu, AiOutlineUser, AiOutlineShoppingCart, AiOutlineSearch} from "react-icons/ai";
import { TextField, LinearProgress } from '@mui/material';
import { Button } from "@mui/base";
import { CartItemCounter } from "../Wrappers/CartItemCounter";

export function NavBar(props) {

    const [show, setShow] = useState(false);
    const [showSearch, setShowSearch] = useState(false);

    const handleClose = () => {setShow(false)};
    const navigation = useNavigation();
    
    return(
        <>
            <Navbar bg="light">
                <Container>
                    
                    <Navbar.Brand href="#" className="p-2"><Link to="/"><img style={{width:"100px"}} src="/TACKSWAPLOGO_Black.png"/></Link></Navbar.Brand>
                    
                    <Navbar.Collapse className="justify-content-end">
                        { props.showProfile &&
                            <Link to="/profile" className="navButton"><AiOutlineUser /></Link>
                        }
                        { props.showCart &&
                            <Link to="/cart" className="navButton">
                                <div style={{position:"relative"}}>
                                    <AiOutlineShoppingCart/>
                                    
                                    <CartItemCounter render={count => (
                                        (count > 0 &&
                                            <div className="ts-badge">
                                                <p>{count}</p>
                                            </div>
                                        )
                                    )}/>
                                </div>
                            </Link>
                        }
                        { props.showSearchIcon &&
                            <Nav.Link onClick={()=>setShowSearch(true)}><AiOutlineSearch/></Nav.Link>
                        }
                        { props.showSidebar &&
                            <Nav.Link><AiOutlineMenu onClick={()=>setShow(true)}/></Nav.Link>
                        }
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            {navigation.state == "loading" && <LinearProgress/>}
            
            { props.showSearch &&
                <Container>
                    <Row className="py-4">
                        <Col style={{paddingLeft:"1.5rem", paddingRight:"0px"}}>
                            <TextField style={{width:"100%"}} type="text" name="search" id="outlined-basic" label="Search for anything" variant="outlined" />
                        </Col>
                        <Col xs={2} style={{paddingRight:"1rem", paddingLeft:"0px"}}>
                            <Button className="mainSearchButton" style={{height:"100%", position:"relative", left:"-3px"}} variant="contained"><AiOutlineSearch/></Button>
                        </Col>
                    </Row>
                </Container>
            }
            
            { props.showSidebar &&
                <Offcanvas show={show} onHide={handleClose} placement="end">
                    <Offcanvas.Header closeButton>
                    {/* <Offcanvas.Title>Offcanvas</Offcanvas.Title> */}
                    <Link to="/" onClick={()=>handleClose()}>
                        <img style={{width:"100px"}} src="/TACKSWAPLOGO_Black.png"/>
                    </Link>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <h4>Categories</h4>
                        <h4>Deals</h4>
                        <h4>Sell</h4>
                        <h4>Help</h4>
                    </Offcanvas.Body>
                </Offcanvas>
            }

            { props.showSearchIcon &&
                <Offcanvas show={showSearch} onHide={()=>setShowSearch(false)} placement="end">
                    <Offcanvas.Header closeButton>
                    <Link to="/" onClick={()=>setShowSearch(false)}>
                        <img style={{width:"100px"}} src="/TACKSWAPLOGO_Black.png"/>
                    </Link>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <Container>
                            <Row className="py-4">
                                <Col style={{paddingLeft:"1.5rem", paddingRight:"0px"}}>
                                    <TextField style={{width:"100%"}} type="text" name="search" id="outlined-basic" label="Search for anything" variant="outlined" />
                                </Col>
                                <Col xs={2} style={{paddingRight:"1rem", paddingLeft:"0px"}}>
                                    <Button className="mainSearchButton" style={{height:"100%", position:"relative", left:"-3px"}} variant="contained"><AiOutlineSearch/></Button>
                                </Col>
                            </Row>
                        </Container>
                    </Offcanvas.Body>
                </Offcanvas>
            }
        </>
    )
}