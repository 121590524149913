import { getUserOrNull, productTileFragment } from "../Helpers"
import { redirect } from "react-router-dom";
import { API, graphqlOperation } from "aws-amplify";

const cartQuery = `
query GetCart($userId: ID!) {
    getUser(id: $userId) {
        cart {
            items {
                id
                ${productTileFragment}
            }
        }
    }
}
`

export async function cartLoader() {

    const user = await getUserOrNull()
    if (user == null) {
        return redirect("/auth/profile")
    }
    const userId = user.attributes.sub
    const result = await API.graphql(graphqlOperation(cartQuery, {userId: userId}))
    return {
        cart: result.data.getUser.cart,
        userId
    }

    // return cart

    const cart = [{
        id: "7d597150-d5c2-4073-a733-c0e70a4c6a62",
        title: "Ivy Full Seat Breeches",
        description: "Fancy breeches - only worn once for a competition but they did not fit me right. Otherwise, a lovely breathable product.",
        mainImg: "/TwoToned-Navy-Main.jpg",
        imgs: ["/TwoToned-Navy-Main.jpg", "/TwoToned-Navy-Main.jpg", "/TwoToned-Navy-Main.jpg", "/TwoToned-Navy-Main.jpg", "/TwoToned-Navy-Main.jpg",],
        shippingOptions: {
            price:"7.00",
        },
        returnOptions: {
            accepted:true,
        },
        soldBy: {
            id:"1627192",
            name: "Bob's Tack",
            itemsSoldCount: 279,
            followers: 295,
            aboutDescription: "Welcome, this is our tack shop, we sell tack stuff.",
            rating: {
                average: 4.75,
                count: 279
            }
        }
    }]

    return cart
}