export function FormatOrderId(id) {
    return id.slice(-5)
}

export function FormatPrice(price) {
    return `$${(price / 100).toFixed(2)}`
}

export function ProductPrice({ product }) {
    return (
        <div className="priceSection">
            {product.salePrice != null && <p className="salePrice">{FormatPrice(product.salePrice)}</p>}
            <p className={`price ${product.salePrice ? "struck" : ""}`}>{FormatPrice(product.price)}</p>
        </div>
    )
}