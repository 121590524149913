import { API, graphqlOperation } from 'aws-amplify';
import { getUserOrNull, productTileFragment } from '../Helpers';
import { redirect } from 'react-router-dom';


const favoritedQuery = `
query GetUserFavorites($id: ID!) {
    getUser(id: $id) {
        favoriteUsers {
            items {
                favorite {
                    id
                }
            }
        }
        favoriteProducts {
            items {
                ${productTileFragment}
            }
        }
    }
}
`

export async function favoritedLoader(userId) {
    const user = await getUserOrNull()
    if (user === null) {
        return redirect("/auth/favorited")
    }
    const favorited = await API.graphql(graphqlOperation(
        favoritedQuery,
        { id: user.attributes.sub }
    ))
    return favorited.data.getUser

    // const favorited = {
    //     sellers: [
    //         {
    //             id: "7d597150-d5c2-4073-a733-c0e70a4c6a62",
    //             title: "Ivy Full Seat Breeches",
    //             brand: "PS of Sweden",
    //             price: "30.00",
    //             salePrice: "25.00",
    //             condition: "New",
    //             seller: {
    //                 name: "Bob's Tack"
    //             },
    //         },
    //         {
    //             id: "7d597150-d5c2-4073-a733-c0e70a4c6a62",
    //             title: "Prelude Full Seat Breech",
    //             brand: "Ariat",
    //             price: "60.00",
    //             salePrice: "49.00",
    //             condition: "Used",
    //             seller: {
    //                 name: "Bob's Tack"
    //             }
    //         },
    //         {
    //             id: "7d597150-d5c2-4073-a733-c0e70a4c6a62",
    //             title: "Estelle Long Sleeve Magic Shirt",
    //             brand: "PS of Sweden",
    //             price: "30.00",
    //             salePrice: "",
    //             condition: "New",
    //             seller: {
    //                 name: "Bob's Tack"
    //             }
    //         }
    //     ],
    //     products: [
    //         {
    //             id: "7d597150-d5c2-4073-a733-c0e70a4c6a62",
    //             title: "Ivy Full Seat Breeches",
    //             brand: "PS of Sweden",
    //             price: "30.00",
    //             salePrice: "25.00",
    //             condition: "New",
    //             seller: {
    //                 name: "Bob's Tack"
    //             },
    //         },
    //         {
    //             id: "7d597150-d5c2-4073-a733-c0e70a4c6a62",
    //             title: "Prelude Full Seat Breech",
    //             brand: "Ariat",
    //             price: "60.00",
    //             salePrice: "49.00",
    //             condition: "Used",
    //             seller: {
    //                 name: "Bob's Tack"
    //             }
    //         },
    //         {
    //             id: "7d597150-d5c2-4073-a733-c0e70a4c6a62",
    //             title: "Estelle Long Sleeve Magic Shirt",
    //             brand: "PS of Sweden",
    //             price: "30.00",
    //             salePrice: "",
    //             condition: "New",
    //             seller: {
    //                 name: "Bob's Tack"
    //             }
    //         }
    //     ]
    // }
    // return favorited
}