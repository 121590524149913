import { API, graphqlOperation } from 'aws-amplify';
import { getUserOrNull } from '../Helpers';
import { redirect } from 'react-router-dom';


const threadQuery = `
query GetThread($id: ID!) {
    getThread(id: $id) {
        id
        messages {
            items {
                createdAt
                content
                id
                sentBy {
                    id
                    name
                    image
                }
            }
        }
        users {
            items {
                user {
                    id
                    name
                    image
                }
            }
        }
    }
}
`

export async function messageLoader(threadId) {
    const user = await getUserOrNull()
    if (user === null) {
        return redirect("/auth/favorited")
    }
    const messages = await API.graphql({
        query: threadQuery,
        variables: {
            id: threadId,
        }
    })
    const thread = messages.data.getThread
    if (thread == null) {
        return redirect("/messages")
    }
    return { thread, userId: user.attributes.sub }
}