import '../../App.scss';
import {React} from "react";
import {Link, useLoaderData} from "react-router-dom";
import {Container, Row, Col} from 'react-bootstrap/';
import { NavBar } from '../../Components/NavBar';
import { Button } from '../../Components/Button';
import {CartTile} from '../../Components/CartTile';
import { Scroller } from '../../Components/Scroller';
import { ProductTile } from '../../Components/ProductTile';
import { FormatPrice, FormatOrderId } from '../../Components/Formatter';

export default function OrderView(){

    const order =  useLoaderData();

    var relatedProducts = [
        {
            id: "7d597150-d5c2-4073-a733-c0e70a4c6a62",
            title: "Ivy Full Seat Breeches",
            brand: "PS of Sweden",
            price: "30.00",
            salePrice: "25.00",
            condition: "New",
        },
        {
            id: "7d597150-d5c2-4073-a733-c0e70a4c6a62",
            title: "Prelude Full Seat Breech",
            brand: "Ariat",
            price: "60.00",
            salePrice: "49.00",
            condition: "Used",
            seller:{
                name: "Bob's Tack"
            }
        },
        {
            id: "7d597150-d5c2-4073-a733-c0e70a4c6a62",
            title: "Estelle Long Sleeve Magic Shirt",
            brand: "PS of Sweden",
            price: "30.00",
            salePrice: "",
            condition: "New",
            seller: {
                name: "Bob's Tack"
            }
        },
        {
            id: "7d597150-d5c2-4073-a733-c0e70a4c6a62",
            title: "Estelle Long Sleeve Magic Shirt",
            brand: "PS of Sweden",
            price: "30.00",
            salePrice: "",
            condition: "New",
            seller: {
                name: "Bob's Tack"
            }
        },
        {
            id: "7d597150-d5c2-4073-a733-c0e70a4c6a62",
            title: "Estelle Long Sleeve Magic Shirt",
            brand: "PS of Sweden",
            price: "30.00",
            salePrice: "",
            condition: "New",
        },
        {
            id: "7d597150-d5c2-4073-a733-c0e70a4c6a62",
            title: "Estelle Long Sleeve Magic Shirt",
            brand: "PS of Sweden",
            price: "30.00",
            salePrice: "",
            condition: "Used",
        },
        {
            id: "7d597150-d5c2-4073-a733-c0e70a4c6a62",
            title: "Ivy Full Seat Breeches",
            brand: "PS of Sweden",
            price: "30.00",
            salePrice: "25.00",
            condition: "New",
        },
        {
            id: "7d597150-d5c2-4073-a733-c0e70a4c6a62",
            title: "Estelle Long Sleeve Magic Shirt",
            brand: "PS of Sweden",
            price: "30.00",
            salePrice: "",
            condition: "New",
        },
        
    ]

    return(
        <>
            <NavBar
                showProfile={true}
                showSidebar={true}
                showSearchIcon={true}
            />
            <Container>
                <Row className="py-4">
                    <Col>
                        <h1>Order #{FormatOrderId(order.id)}</h1>
                        <p><span>Status:</span> {order.status}</p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {order.product.items.map ((product) => ( 
                            <Col className="px-1">
                                <CartTile
                                    actionButtons={false}
                                    product = {product.product}
                                />
                            </Col>
                        ))}
                    </Col>
                </Row>
                <Row>
                    <Col className="cartSubtotal second">
                        <h6>Total </h6>
                        <h6>{FormatPrice(order.total)}</h6>
                    </Col>  
                </Row>
                <Row>
                    {/* <Col className="cartSubtotal third">
                        <h6>Shipping</h6>
                        <h6>Calculated at checkout</h6>
                    </Col>   */}
                </Row>
                <Row>
                    <Col>
                    </Col>
                </Row>
                <Row>
                    <Col className="py-4">
                        <h2>Related Products</h2>

                        <Scroller style={{paddingTop:"10px", paddingBottom:"25px"}}>
                            {relatedProducts.map ((product) => (
                                <Col xs={5} md={3} className="px-1">
                                    <ProductTile
                                        product = {product}
                                    />
                                </Col>

                            ))}
                        </Scroller>
                    </Col>
                </Row>
            </Container>
        </>
    );
}
