import {React} from "react";
import {Link, useLoaderData} from "react-router-dom";
import {Container, Row, Col} from "react-bootstrap/";
import { ProductTile } from "../Components/ProductTile";
import { Button } from "../Components/Button";

export default function CollectionView(){

    const collections = useLoaderData();

    return(
        <Container>
            <Row>
                <Col>
                    <h1>Collection View</h1>
                </Col>
            </Row>
            {collections.map((collection) => (
                <Row>
                    {collection.products.items.map((product) => (
                        <Col>
                            <ProductTile product={product}/>
                        </Col>
                    ))}
                </Row>
            ))}
            <Row>
                <Button>Create Collection</Button>
            </Row>
        </Container>
    );
}