import React from 'react';

export function CircleButton(props) {

    var onClick = props.onClick;


    if (props.setIsProcessing) {
        onClick = () => {
            props.setIsProcessing(true)
            props.onClick().then(() => {
                props.setIsProcessing(false)
            })
        }
    }

    return(
        <div className={`${props.className} circleBtnWrapper`}>
            <button className={`circleBtn ${props.sm && "sm"} ${props.largeTxt && "largeTxt"} ${props.variant && "variant"} ${props.isFavorited == true && "isFavorited"} ${(props.isFavorited == false) && !props.maintainDefault && "favorite"}`} onClick={onClick} disabled={props.isProcessing == true}>
                {props.children}
            </button>
            {props.textLabel && <p className="circleBtn-textLabel">{props.textLabel}</p>}
        </div>
    )
}
