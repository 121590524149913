import React from "react";
import { Container, Row, Col} from "react-bootstrap";
import {useLoaderData, Link} from "react-router-dom";
import { OrderTile } from "../../Components/OrderTile";

export default function OrdersView(){

    const orders = useLoaderData();

    return(
        <Container>
            <Row>
                <Col>
                    <div className="profile-orders">
                        <h1>Orders</h1>
                        {orders.map ((order) => (
                            <Col xs={12} md={4} className="px-1 py-2">
                                <OrderTile
                                    order = {order}
                                />
                            </Col>
                        ))}
                    </div>
                </Col>
            </Row>
        </Container>
    );
}