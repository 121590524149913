import React, { useState, useEffect, useCallback } from "react";
import { Container, Row, Col} from "react-bootstrap";
import { useLoaderData, useNavigate, Link} from "react-router-dom";
import { AiOutlinePlus, AiOutlineSearch } from "react-icons/ai";
import { BiChevronsRight } from 'react-icons/bi';
import ReactTimeAgo from 'react-time-ago';
import { NavBar } from "../../Components/NavBar";
import { CircleButton } from "../../Components/CircleButton.js";
import Offcanvas from 'react-bootstrap/Offcanvas';
import TextField from '@mui/material/TextField';
import { Button } from '@mui/base';
import { SubscribeWrapper, UpdateTypes } from "../../Wrappers/SubscribeWrapper";
import { CircularProgress } from '@mui/material';

import {FormControl, Autocomplete} from "@mui/material";


import { API } from "aws-amplify";


export default function MessagesView() {

    const { userId, userName, ...data } = useLoaderData();

    const [threads, setThreads] = useState(data.threads)

    const [newMessageShow, setNewMessageShow] = useState(false);
    const [newMessageTo, setNewMessageTo] = useState("")

    const [suggestions, setSuggestions] = useState([])

    const handleNewMessageClose = () => {setNewMessageShow(false)};
    const handleNewMessageShow = () => {setNewMessageShow(true)};

    const [isProcessingUsers, setIsProcessingUsers] = useState(false);

    const navigate = useNavigate();

    const updateThreads = useCallback(({ type, value }) => {
        if (type === UpdateTypes.CREATE) {
            console.log(value)
            API.graphql({
                query: `
                    query GetThread($id: ID!) {
                        getThread(id: $id) {
                            createdAt
                            id
                            messages(limit: 1, sortDirection: DESC) {
                                items {
                                    createdAt
                                    content
                                }
                            }
                            users {
                                items {
                                    user {
                                        id
                                        name
                                        image
                                    }
                                }
                            }
                        }
                    }
                `,
                variables: {
                    id: value.data.onCreateThread.id
                }
            }).then((value) => {
                const thread = value.data.getThread
                setThreads(threads => [...threads, thread])
            })
        }
    }, [])

    async function getThread(otherUser) {
        for (const t of threads) {
            if (t.users.items.filter((u) => u.user.id === otherUser.id).length > 0) {
                return t.id
            }
        }
        const owners = [
            `${userId}::${userName}`,
            `${otherUser.id}::${otherUser.name}`
        ]

        const thread = await API.graphql({
            query: `
                mutation CreateThread($owners: [String!]!) {
                    createThread(input: {owners: $owners}) {
                        createdAt
                        id
                        messages(limit: 1, sortDirection: DESC) {
                            items {
                                createdAt
                                content
                            }
                        }
                        users {
                            items {
                                user {
                                    id
                                    name
                                    image
                                }
                            }
                        }
                    }
                }
            `,
            variables: {
                owners: owners
            }
        })
        const threadId = thread.data.createThread.id
        await API.graphql({
            query: `
                mutation CreateUserThread($threadId: ID!, $userId: ID!, $owners: [String!]!) {
                    createUserThread(input: {threadId: $threadId, userId: $userId, owners: $owners}) {
                        id
                    }
                }
                `,
            variables: {
                threadId: threadId,
                userId: userId,
                owners: owners
            }
        })
        await API.graphql({
            query: `
                mutation CreatOtherUserThread($threadId: ID!, $userId: ID!, $owners: [String!]!) {
                    createUserThread(input: {threadId: $threadId, userId: $userId, owners: $owners}) {
                        id
                    }
                }
                `,
            variables: {
                threadId: threadId,
                userId: otherUser.id,
                owners: owners
            }
        })
        return threadId
    }

    useEffect(() => {
        
        if (newMessageTo === "") {
            setSuggestions([])
            return
        }
        setIsProcessingUsers(true);

        const promise = API.post("tackswapGateway", "/search", {
            body: {
                operation: "find_user",
                query: newMessageTo
            }
        })

        promise.then((result) => {
            setIsProcessingUsers(false);
            setSuggestions(result.hits.hits.map((h) => h._source))
        }).catch((err) => {
            if (err.message != "Operation cancelled") {
                console.log(err)
            }
        })
        return () => { API.cancel(promise, "Operation cancelled") }
    }, [newMessageTo])
    
    return(
        <>
            
            <NavBar
                showProfile={true}
                showCart={true}
                showSidebar={true}
                showSearchIcon={true}
            />

            <Container>
                <Row className="py-4">
                    <Col style={{paddingLeft:"1.5rem", paddingRight:"0px"}}>
                        <TextField style={{width:"100%"}} type="text" name="search" id="outlined-basic" label="Search Messages" variant="outlined" />
                    </Col>
                    <Col xs={2} style={{paddingRight:"1rem", paddingLeft:"0px"}}>
                        <Button className="mainSearchButton" style={{height:"100%", position:"relative", left:"-3px"}} variant="contained"><AiOutlineSearch/></Button>
                    </Col>
                </Row>
            </Container>

            <Container>
                <Row className="py-2">
                    <Col xs={12} className="inputHeader">
                        <h1>Messages</h1>

                        <CircleButton sm onClick={handleNewMessageShow}>
                            <AiOutlinePlus/>
                        </CircleButton>
                    </Col>
                </Row>
                <SubscribeWrapper
                    queryName="ThreadSubscription"
                    query={
                    `Thread {
                        id
                    }`}
                    onUpdate={updateThreads}
                />
                <Row>
                    {threads.map((thread) => {
                        console.log(threads)
                        const otherUser = thread.users.items.filter((u) => u.user.id !== userId)[0].user
                        console.log(otherUser)
                        const date = new Date(thread.messages.items[0]?.createdAt ?? thread.createdAt)
                        return (
                            <Col xs={12}>
                                <Link to={`/profile/message/${thread.id}`}>
                                    <div className="thread">
                                        <div className="profileImgWrapper">
                                            <img src={otherUser.image ?? "/profilePlaceholder.jpeg"}/>
                                        </div>
                                        <div className="threadContentWrapper">
                                            <div className="threadContent">
                                                <h5>{otherUser.name}</h5>
                                                <p><ReactTimeAgo date={date}/></p>
                                            </div>

                                            <BiChevronsRight style={{fontSize:"1.4rem"}}/>
                                        </div>
                                    </div>
                                </Link>
                            </Col>
                    )})}
                </Row>
            </Container>

            <Offcanvas show={newMessageShow} onHide={handleNewMessageClose} placement="end">
                <Offcanvas.Header closeButton>
                {/* <Offcanvas.Title>Offcanvas</Offcanvas.Title> */}
                <Link to="/" onClick={()=>handleNewMessageClose()}>
                    <img style={{width:"100px"}} src="/TACKSWAPLOGO_Black.png"/>
                </Link>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Container>
                        <h4 style={{marginBottom:"20px"}}>New Message</h4>
                        <Row className="py-4">
                            <Col style={{}}>
                                <TextField style={{width:"100%"}}
                                    type="text" name="search"
                                    variant="standard"
                                    label="To"
                                    value={newMessageTo}
                                    onChange={(e)=>{ setNewMessageTo(e.target.value) }}
                                />
                            </Col>
                            {/* <Col xs={2} style={{paddingRight:"1rem", paddingLeft:"0px"}}>
                            </Col> */}
                        </Row>
                        <Row>
                            <div className="selectableUserList">
                            {isProcessingUsers ? 
                                (
                                <div style={{textAlign:"center"}}>
                                    <CircularProgress disableShrink/> 
                                </div>
                                ) : 
                                (
                                    suggestions.map((suggestion) => (
                                        <div className="selectableUser" onClick={async () => {
                                            const threadId = await getThread(suggestion)
                                            navigate(`/profile/message/${threadId}`)
                                        }}>
                                            <h4>{suggestion.name}</h4>
                                        </div>
                                    ))
                                )
                            }
                            </div>
                        </Row>
                    </Container>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
}