import React, { useState } from 'react';
import {Container, Row, Col} from 'react-bootstrap/';
import { Scroller } from '../Components/Scroller';
import { Link } from "react-router-dom";
import Carousel from 'react-bootstrap/Carousel';
import {useLoaderData} from "react-router-dom";
import {AiOutlineHeart, AiFillHeart, AiOutlineMail, AiOutlineThunderbolt} from "react-icons/ai";
import {BsTruck} from "react-icons/bs";
import {BiStore} from "react-icons/bi";
import { CircleButton } from '../Components/CircleButton';
import { Button } from '../Components/Button';
import { Grid } from '../Components/Grid';
import { ProductTile } from '../Components/ProductTile';
import { ProductPrice } from '../Components/Formatter';

import { CircularProgress } from '@mui/material';
import { FavoriteSellerWrapper, FavoriteProductWrapper, CartProductWrapper } from '../Wrappers/RelationWrappers';

export default function ProductView(){

    const {userId, product, favoriteUserRelationId, favoriteProductRelationId, cartRelationId} = useLoaderData()

    const [favoriteUserProcessing, setFavoriteUserProcessing] = useState(false)
    const [favoriteProductProcessing, setFavoriteProductProcessing] = useState(false)
    const [cartProcessing, setCartProcessing] = useState(false)

    var recentlyViewedProducts = [
        {
            id: "7d597150-d5c2-4073-a733-c0e70a4c6a62",
            title: "Ivy Full Seat Breeches",
            brand: "PS of Sweden",
            price: "30.00",
            salePrice: "25.00"
        },
        {
            id: "1782662",
            title: "Prelude Full Seat Breech",
            brand: "Ariat",
            price: "60.00",
            salePrice: "49.00"
        },
        {
            id: "1726262",
            title: "Estelle Long Sleeve Magic Shirt",
            brand: "PS of Sweden",
            price: "30.00",
            salePrice: ""
        },
        {
            id: "1782662",
            title: "Lux GripTEQ Riding Pants",
            brand: "Equestly",
            price: "80.00",
            salePrice: ""
        },
        {
            id: "178262",
            title: "Estelle Long Sleeve Magic Shirt",
            brand: "PS of Sweden",
            price: "30.00",
            salePrice: ""
        },
        {
            id: "126262",
            title: "Estelle Long Sleeve Magic Shirt",
            brand: "PS of Sweden",
            price: "30.00",
            salePrice: ""
        },
        {
            id: "17862",
            title: "Ivy Full Seat Breeches",
            brand: "PS of Sweden",
            price: "30.00",
            salePrice: "25.00"
        },
        {
            id: "6262",
            title: "Estelle Long Sleeve Magic Shirt",
            brand: "PS of Sweden",
            price: "30.00",
            salePrice: ""
        },
        
    ]

    return(
        <Container>
            <Row>
                <Col xs={12} md={5} className="productCarouselImageWrapper px-0">
                    <Carousel>
                        {product.images.map ((img) => (
                            <Carousel.Item>
                                <img
                                    className="d-block w-100 productCarouselImage"
                                    src={img}
                                />
                            </Carousel.Item>
                        ))}
                    </Carousel>
                </Col>
                <Col md={1}></Col>
                <Col className="productViewInfo px-4">
                    <div className="productTextSection">
                        <div className="productTextHeader">
                            <div className>
                                <h5 className="brand">{product.brand}</h5>
                                <h2>{product.title}</h2>
                            </div>

                            <FavoriteProductWrapper
                                userId={userId}
                                productId={product.id}
                                relationId={favoriteProductRelationId}
                                render={({toggleRelation, relationExists}) => (
                                    <CircleButton largeTxt isFavorited={relationExists} onClick={toggleRelation} isProcessing={favoriteProductProcessing} setIsProcessing={setFavoriteProductProcessing}>
                                        { favoriteProductProcessing ? <CircularProgress style={{color: relationExists ? "#fff" : "#000"}} disableShrink/> : (
                                            relationExists ? <AiFillHeart/> : <AiOutlineHeart/> 
                                        )}
                                    </CircleButton>
                                )}
                            />
                        </div>
                        
                        <ProductPrice product={product} />
                        <p>{product.description}</p>

                        <CartProductWrapper
                            userId={userId}
                            productId={product.id}
                            relationId={cartRelationId}
                            render={({toggleRelation, relationExists}) => (
                                <Button variant={true} onClick={toggleRelation} isProcessing={cartProcessing} setIsProcessing={setCartProcessing}>
                                    { cartProcessing ? <CircularProgress disableShrink/> : (
                                        relationExists ? "In Cart" : "Add to Cart"
                                    )}
                                </Button>
                            )}
                        />
                        
                        <Button>Buy Now</Button>
                    </div>
                
                    <Row>
                        <Col>
                            <div className="sellingPoints">
                                <div className="sellingPoint gx-0">
                                    <div xs={1}>
                                        <div className="icon"><AiOutlineThunderbolt/></div>
                                    </div>
                                    <div>
                                        <p>10 views in the last 7 days</p>
                                    </div>
                                </div>
                                <div className="sellingPoint gx-0">
                                    <div xs={1}>
                                        <div className="icon"><BsTruck/></div>
                                    </div>
                                    <div>
                                        <p>Free Shipping!</p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    
                    <Row className="additionalInfos">
                        <Row className="additionalInfo">
                            <Col xs={3} className="px-0">
                                <p>Shipping:</p>
                            </Col>
                            <Col className="px-0">
                                <p>Fri, July 10</p> {/*replace with math (todays date plus estimated shipping from product) */}
                                <p>Shipping from: {}Spokane, Washington, United States</p> {/*inset shipping from from "product shipping from"*/}
                            </Col>
                        </Row>

                        <Row className="additionalInfo">
                            <Col xs={3} className="px-0">
                                <p>Returns:</p>
                            </Col>
                            <Col className="px-0">
                                <p>30 days returns. Seller pays for return shipping.</p> {/*inset shipping from from "product shipping from"*/}
                            </Col>
                        </Row>
                    </Row>
                    <Row>
                        <Col>
                            <div className="sellerInfo">
                                <Row>
                                    <h5 className="title">Seller</h5>
                                    <Link to={`/seller/${product.soldBy.id}`} className="sellerName"><p>{product.soldBy.name}</p></Link>
                                    <Col style={{textAlign:"center"}}>
                                        <FavoriteSellerWrapper
                                            userId={userId}
                                            sellerId={product.soldBy.id}
                                            relationId={favoriteUserRelationId}
                                            render={({toggleRelation, relationExists}) => (
                                                <CircleButton isFavorited={relationExists} maintainDefault textLabel={relationExists ? "Favorited" : "Favorite"} onClick={toggleRelation} isProcessing={favoriteUserProcessing} setIsProcessing={setFavoriteUserProcessing}>
                                                    { favoriteUserProcessing ? <CircularProgress style={{color:"#000"}} disableShrink/> : (
                                                        relationExists ? <AiFillHeart/> : <AiOutlineHeart/> 
                                                    )}
                                                </CircleButton>
                                            )}
                                        />
                                    </Col>
                                    <Col style={{textAlign:"center"}}>
                                        <CircleButton textLabel="Contact"><AiOutlineMail/></CircleButton>
                                    </Col>
                                    <Col style={{textAlign:"center"}}>
                                        <CircleButton textLabel="Store"><BiStore/></CircleButton>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h2>Similar Items</h2>

                    <Scroller style={{paddingTop:"10px", paddingBottom:"25px"}}>
                        {recentlyViewedProducts.map ((product) => (
                            <Col xs={5} md={3} className="px-1">
                                <ProductTile
                                    product = {product}
                                />
                            </Col>

                        ))}
                    </Scroller>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h2>In other people's cart</h2>
                    <Grid style={{padding:"10px 15px 25px"}}>
                        {recentlyViewedProducts.map ((product) => (
                            <Col xs={6} md={3} className="px-1">
                                <ProductTile
                                    product = {product}
                                    isGrid={true}
                                />
                            </Col>
                        ))}
                    </Grid>
                </Col>
            </Row>
        </Container>
    );
}