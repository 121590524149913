// import { Button } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";


export function CategoriesButton(props){

    return(
        <div className="categoriesButtonWrapper">
            {props.link ? 
                <Link to={props.link}>
                    <button className="categoriesButton">
                        {props.text}
                    </button>
                </Link>
            : 
                <button className="categoriesButton" onClick={props.onClick}>
                    {props.text}
                    {props.deletable && <i className="fas fa-times"></i>}
                </button>
            }
            {/* <Link to="/category/1234">
                <button className="categoriesButton">
                    {props.text}
                </button>
            </Link> */}
        </div>
    );
}