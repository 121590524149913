import React from "react";
import { Row } from "react-bootstrap";
import { AiOutlineRight } from "react-icons/ai";

export function Scroller(props){

    return(
        <Row className="scroller infinite-scroll-left" style={props.style}>
            {props.children}
            {/* <div className="scrollerRightIndicator">
                <AiOutlineRight />
            </div> */}
            
        </Row>
    )
}
