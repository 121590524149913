import { redirect } from "react-router-dom";
import { getUserOrNull, productTileFragment } from "../Helpers";
import { Auth, API } from "aws-amplify";

const collectionQuery = `
query GetUserCollections($userId: ID!) {
    getUser(id: $userId) {
        collections {
            items {
                id
                name
                image
            }
        }
    }
}`

export async function sellLoader() {
    const user = await getUserOrNull()
    if (user == null) {
        return redirect("/auth/sell")
    }
    const identity = (await Auth.currentUserCredentials()).identityId
    const sub = user.attributes.sub

    const result = await API.graphql({
        query: collectionQuery,
        variables: { userId: sub }
    })

    return {
        sub: sub,
        identity,
        collections: result.data.getUser.collections.items
    }
}