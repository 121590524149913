import { redirect } from "react-router-dom";
import { getUserOrNull } from "../Helpers";
import { API } from "aws-amplify";

export async function onboardSellerLoader(doContinue) {
    const user = await getUserOrNull()
    if (user == null) {
        return redirect("/auth/profile")
    }
    if (doContinue) {
        const link = await API.post("tackswapGateway", "/stripe/createAccount")
        return redirect(link.url)
    }
    return null
}