import React from "react";
import { Row } from "react-bootstrap";

export function Grid(props){

    return(
        <Row className="" style={props.style}>
            {props.children}
        </Row>
    )
}
