import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";

import { Amplify } from "aws-amplify";
import awsconfig from "./aws-exports";

// Pages
import Main from "./Main";
import Error from "./Pages/Error";
import NotFound from "./Pages/NotFound";
import HomePage from "./Pages/HomePage";
import ProductView from "./Pages/ProductView";
import SellerView from "./Pages/SellerView";
import CollectionView from "./Pages/CollectionView";
import CartView from "./Pages/CartView";
import CategoriesView from "./Pages/CategoriesView";

import ProfileView from "./Pages/Profile/ProfileView";
import OrderView from "./Pages/Profile/OrderView";
import Favorited from "./Pages/Profile/Favorited";
import MessagesView from "./Pages/Profile/MessagesView";
import MessageView from "./Pages/Profile/MessageView";
import SellView from "./Pages/Profile/SellView";

// User
import UserAuth from "./Pages/UserAuth";
import UserView from "./Pages/UserView";

// Loaders
import { homeLoader } from "./Loaders/HomeLoader";
import { productLoader } from "./Loaders/ProductLoader";
import { sellerLoader } from "./Loaders/SellerLoader";
import { collectionLoader } from "./Loaders/CollectionLoader";
import { cartLoader } from "./Loaders/CartLoader";
import { categoriesLoader } from "./Loaders/CategoriesLoader";
import { userLoader } from "./Loaders/UserLoader";
import { profileLoader } from "./Loaders/ProfileLoader";
import { ordersLoader } from "./Loaders/OrdersLoader";
import { orderLoader } from "./Loaders/OrderLoader";
import { favoritedLoader } from "./Loaders/FavoritedLoader";
import { sellLoader } from "./Loaders/SellLoader";
import { messagesLoader } from "./Loaders/MessagesLoader";
import { messageLoader } from "./Loaders/MessageLoader";
import { onboardSellerLoader } from "./Loaders/OnboardSellerLoader";

import reportWebVitals from "./reportWebVitals";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import OrdersView from "./Pages/Profile/Orders";

import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en.json";
import OnboardSeller from "./Pages/Profile/OnboardSellerView";
TimeAgo.addDefaultLocale(en);

Amplify.configure(awsconfig);

const router = createBrowserRouter([
  {
    path: "/",
    element: <Main/>,
    errorElement: <Error />,
    children: [
        {index:true, element:<HomePage/>,
          loader: async ({ params }) => {
            return await homeLoader(params.id);
          }
        },
        {
          path: "notfound",
          element: <NotFound />
        },
        {
          path: "product/:id",
          element: <ProductView />,
          loader: async ({ params }) => {
            return await productLoader(params.id);
          },
        },
        {
          path: "seller/:id",
          element: <SellerView />,
          loader: async ({ params }) => {
            return sellerLoader(params.id);
          },
        },
        {
          path: "collection",
          element: <CollectionView />,
          loader: async () => {
            return await collectionLoader();
          },
        },
        {
          path: "category/:id",
          element: <CategoriesView/>,
          loader: async ({ params }) => {
            return categoriesLoader(params.id);
          },
        },
        {
          path: "profile/",
          element: <ProfileView />,
          loader: async () => {
            return await profileLoader();
          }
        },
        // PROFILE SUB => {something like this}
        {
          path: "profile/orders/",
          element: <OrdersView />,
          loader: async () => {
            return await ordersLoader();
          }
        },
        {
          path: "profile/account/",
          element: <OrdersView />,
          loader: async () => {
            return await profileLoader();
          }
        },
        {
          path: "profile/favorited/",
          element: <Favorited />,
          loader: async () => {
            return await favoritedLoader();
          }
        }
      ],
  },
  {
    path: "profile/messages/",
    element: <MessagesView />,
    loader: async () => {
      return await messagesLoader();
    }
  },
  {
    path: "profile/message/:id",
    element: <MessageView />,
    loader: async ({ params }) => {
      return await messageLoader(params.id);
    }
  },
  {
    path: "profile/sell/",
    element: <SellView />,
    loader: async () => {
      return await sellLoader();
    }
  },
  {
    path: "profile/onboardseller/:continue?",
    element: <OnboardSeller/>,
    loader: async ({ params }) => {
      return await onboardSellerLoader(params.continue);
    }
  },
  {
    path: "cart/",
    element: <CartView />,
    loader: async () => {
      return await cartLoader();
    },
  },
  {
    path: "order/:id",
    element: <OrderView />,
    loader: async ({ params }) => {
      return orderLoader(params.id);
    },
  },
  {
    path: "auth/*",
    element: <UserAuth/>,
  },
  {
    path: "user/:id",
    element: <UserView />,
    loader: async ({ params }) => {
      return userLoader(params.id);
    },
  },
]);




const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <RouterProvider router={router} />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
