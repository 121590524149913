import { API } from 'aws-amplify';
import { redirect } from "react-router-dom";
import { getUserOrNull } from '../Helpers';

const productQuery = `
getProduct(id: $id) {
    id
    title
    description
    quantity
    images
    mainImage
    condition
    brand
    price
    salePrice
    viewCount
    favoriteCount
    createdAt
    updatedAt

    reviews {
        id
        average
        count
        reviews(limit: 10) {
            items {
                id
                createdAt
                rating
                text
                title
                givenBy {
                    name
                    image
                }
            }
            nextToken
        }
    }

    shippingOptions {
        id
        name
        price
        handlingTime
        shippingTime
    }

    returnOption {
        id
        text
        createdAt
        updatedAt
        __typename
      }

    soldBy {
        id
        name
        image
        description
        itemsSoldCount
        reviewsReceived {
            average
            count
        }
    }
}
`

const userQuery = `
getUser(id: $userId) {
    favoriteUsers {
        items {
            id
            favoriteId
        }
    }
    favoriteProducts {
        items {
            id
            productId
        }
    }
    cart {
        items {
            id
            productId
        }
    }
}`

export async function productLoader(productId) {
    var result
    var userId = null
    const user = await getUserOrNull()

    if (user === null) {
        result = await API.graphql({
            query: `
                query GetProductPage($id: ID!) {
                    ${productQuery}
                }`,
            variables: { id: productId},
            authMode: "AWS_IAM"
        })
    } else {
        userId = user.attributes.sub
        result = await API.graphql({
            query: `
                query GetProductPage($id: ID!, $userId: ID!) {
                    ${productQuery}
                    ${userQuery}
                }`,
            variables: { id: productId, userId: userId }
        })
    }

    const product = result.data.getProduct
    if (product == null) {
        return redirect("/notfound")
    }

    const favoriteUserRelationId = result.data.getUser?.favoriteUsers?.items?.find(i => { return i.favoriteId == product.soldBy.id })?.id ?? null
    const favoriteProductRelationId = result.data.getUser?.favoriteProducts?.items?.find(i => { return i.productId == productId })?.id ?? null
    const cartRelationId = result.data.getUser?.cart?.items?.find(i => { return i.productId == productId })?.id ?? null
    return { userId, product, favoriteUserRelationId, favoriteProductRelationId, cartRelationId }

    // const product = {
    //     id: "7d597150-d5c2-4073-a733-c0e70a4c6a62",
    //     title: "Ivy Full Seat Breeches",
    //     quantity: 1,
    //     price: "30.00",
    //     salePrice: "25.00",
    //     brand: "PS of Sweden",
    //     condition: "Used - Like New",
    //     description: "Fancy breeches - only worn once for a competition but they did not fit me right. Otherwise, a lovely breathable product.",
    //     mainImage: 0,
    //     images: ["/TwoToned-Navy-Main.jpg", "/TwoToned-Navy-Main.jpg", "/TwoToned-Navy-Main.jpg", "/TwoToned-Navy-Main.jpg", "/TwoToned-Navy-Main.jpg",],
    //     shippingOptions: {
    //         price: "7.00",
    //     },
    //     returnOptions: {
    //         accepted:true,
    //     },
    //     soldBy: {
    //         id: "1627192",
    //         name: "Bob's Tack",
    //         itemsSoldCount: 279,
    //         followers: 295,
    //         aboutDescription: "Welcome, this is our tack shop, we sell tack stuff.",
    //         rating: {
    //             average: 4.75,
    //             count: 279
    //         }
    //     },
    //     viewCount: 0,
    //     favoriteCount: 0,
    //     categories: [],
    //     createdAt: "2021-03-01T00:00:00.000Z",
    //     updatedAt: "2021-03-01T00:00:00.000Z",
    // }
}