import React, { useState, useRef } from "react";

import { Container, Row, Col } from "react-bootstrap";
import TextField from "@mui/material/TextField";
import { API, Storage } from "aws-amplify";
import { createProduct, updateUser } from "../../graphql/mutations";
// import { CreateProductInput } from "../../API";
import { CircularProgress } from "@mui/material";
import { useLoaderData } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { Button } from "../../Components/Button";
import { Error } from "../../Components/Error";
import { NavBar } from "../../Components/NavBar";
import { ProductTile } from "../../Components/ProductTile";
import { Link} from "react-router-dom";

// Image Stuff
import { Carousel } from "react-bootstrap";
import { AiOutlinePlus } from "react-icons/ai";
import { BsImages, BsTrash } from "react-icons/bs";
import { FiEdit2 } from "react-icons/fi";
import { CircleButton } from "../../Components/CircleButton";
import Offcanvas from "react-bootstrap/Offcanvas";

import {InputLabel, InputAdornment, OutlinedInput, MenuItem, FormControl, FormLabel, FormGroup, FormControlLabel, Select, Autocomplete, Checkbox} from "@mui/material";

import {allBrands} from "../../Components/AllBrands";
import {allCategories} from "../../Components/AllCategories";
import { CategoriesButton } from "../../Components/CategoriesButton";
import { PriceInput } from "../../Components/PriceInput";
import { create } from "@mui/material/styles/createTransitions";

export default function SellView() {

    const data = useLoaderData()

    const [title, setTitle] = useState("")
    const [description, setDescription] = useState("")
    const [quantity, setQuantity] = useState(1)

    const [collections, setCollections] = useState(data.collections)
    const [selectedCollections, setSelectedCollections] = useState([])

    const [selectedImages, setSelectedImages] = useState([])
    const [mainImage, setMainImage] = useState(0) // Index of images
    const [condition, setCondition] = useState("")
    const [brand, setBrand] = useState("Ariat")
    const [price, setPrice] = useState(5000)

    const [useSalePrice, setUseSalePrice] = useState(false)
    const [salePrice, setSalePrice] = useState(0)

    const [shippingOptions, setShippingOptions] = useState([])
    const [categories, setCategories] = useState([])
    const [selectedCategory, setSelectedCategory] = useState("")

    const [error, setError] = useState(null)
    const [isProcessing, setIsProcessing] = useState(false)

    const imageInput = useRef(null);

    const [showImageManager, setShowImageManager] = useState(false);
    const handleCloseImageManager = () => {setShowImageManager(false)};
    const handleShowImageManager = () => {setShowImageManager(true)};

    const [showCollectionManager, setShowCollectionManager] = useState(false);
    const handleCloseCollectionManager = () => {setShowCollectionManager(false)};
    const handleShowCollectionManager = () => {setShowCollectionManager(true)};

    const [collectionError, setCollectionError] = useState(null)
    const [newCollectionTitle, setNewCollectionTitle] = useState("")
    const [newCollectionImage, setNewCollectionImage] = useState(null)
    const [newCollectionDescription, setNewCollectionDescription] = useState("")
    const newCollectionImageInput = useRef(null);

    function reorderImages(result) {
        const { source, destination } = result;
        if (!destination) {
            return
        }
        const items = Array.from(selectedImages);
        const [reorderedItem] = items.splice(source.index, 1);
        items.splice(destination.index, 0, reorderedItem);
        setSelectedImages(items)
    }

    async function uploadImage(file) {
        const key = uuidv4()
        await Storage.put(key, file, {
            level: "private"
        })
        const url = "https://tackswap-images155144-dev.s3.amazonaws.com/private/" + data.identity + "/" + key
        return url
    }

    async function createCollection() {
        if (!newCollectionTitle) {
            setCollectionError("Title is required")
            return
        }

        const url = await uploadImage(newCollectionImage)

        const newCollection = await API.graphql({query: `
            mutation CreateCollection($input: CreateCollectionInput!) {
                createCollection(input: $input) {
                    id
                    name
                    image
                }
            }`, variables: {
                input: {
                    name: newCollectionTitle,
                    description: newCollectionDescription,
                    image: url,
                    userCollectionsId: data.sub
                }
            }})

        const collection = newCollection.data.createCollection
        setCollections([...collections, collection])
        console.log(collections)

        setNewCollectionTitle("")
        setNewCollectionImage(null)
        setNewCollectionDescription("")
        setShowCollectionManager(false)
    }

    async function sell() {
        var images = []
        for (const file of selectedImages) {
            images.push(await uploadImage(file))
        }

        if (!title) {
            setError("Title is required")
            return
        }
        if (!description) {
            setError("Description is required")
            return
        }
        if (!quantity) {
            setError("Quantity is required")
            return
        }
        if (quantity < 1) {
            setError("Quantity must be greater than 0")
            return
        }
        if (images.length == 0) {
            setError("At least one image is required")
            return
        }
        if (!condition) {
            setError("Condition is required")
            return
        }
        if (!brand) {
            setError("Brand is required")
            return
        }
        if (!price) {
            setError("Price is required")
            return
        }
        if (price < 0) {
            setError("Price must be greater than 0")
            return
        }
        if (useSalePrice && !salePrice) {
            setError("Sale Price is required")
            return
        }
        if (useSalePrice && salePrice < 0) {
            setError("Sale Price must be greater than 0")
            return
        }
        if (useSalePrice && salePrice >= price) {
            setError("Sale Price must be less than Price")
            return
        }

        let product = {
            title: title,
            description: description,
            quantity: quantity,
            images: images,
            mainImage: mainImage,
            condition: condition,
            brand: brand,
            price: price,
            shippingOptions: shippingOptions,
            categories: [],
            viewCount: 0,
            favoriteCount: 0,
            userProductsId: data.sub
        }

        if (useSalePrice) {
                product = {...product, salePrice: salePrice}
        }

        const newProduct = await API.graphql({query: `
            mutation CreateProduct($input: CreateProductInput!) {
                createProduct(input: $input) {
                    id
                }
            }
        `, variables: {
            input: product
        }})
        const productId = newProduct.data.createProduct.id

        for (const collection of selectedCollections) {
            const newCollectionJoins = await API.graphql({query: `
                mutation CreateCollectionProduct($collectionId: ID!, $productId: ID!) {
                    createCollectionProduct(input: {collectionId: $collectionId, productId: $productId}) {
                        id
                    }
                }
            `, variables: {
                collectionId: collection.id,
                productId: productId
            }})
        }
    }

    
    return (
        <>
            <NavBar
                showProfile={true}
                showSidebar={true}
                showSearchIcon={true}
            />
            <Container>
                <Row className="py-4">
                    <Col>
                        <h1>List an Item</h1>
                        <Error text={error} />
                    </Col>
                </Row>

                <Row>
                    <Col xs={12} sm={6}>
                        <Col xs={12} className="inputHeader">
                            <h5>Item Photos</h5>
                            {selectedImages.length != 0 &&
                                <CircleButton sm onClick={()=>handleShowImageManager()}>
                                    <FiEdit2/>
                                </CircleButton>
                            }
                            
                        </Col>
                        <Col xs={12} className="py-2">
                            {selectedImages.length == 0 ? 
                                <div className="inputImageBlock" onClick={()=> {
                                        imageInput.current.click()
                                        handleShowImageManager()
                                    }} 
                                >
                                    <input 
                                        style={{display:"none"}} 
                                        type="file" accept="image/*" 
                                        onChange={(e) => { setSelectedImages([ ...selectedImages, e.target.files?.item(0)]) }} 
                                        ref={imageInput}
                                    />
                                    <BsImages style={{marginBottom:"15px"}}size={40}/>

                                    <h2>Upload Image</h2>
                                    {/* <h5>Or Drag and Drop</h5> */}
                                    <h5>Click to upload</h5>
                                </div>
                            :
                                <Carousel interval={null} className="inputImageCarousel">
                                    {selectedImages.map ((img) => {
                                        console.log(img)
                                        return (
                                            <Carousel.Item>
                                                <img
                                                    className="d-block w-100 inputImage"
                                                    src={URL.createObjectURL(img)}
                                                />
                                            </Carousel.Item>
                                        )
                                    })}
                                    <Carousel.Item>
                                        <div className="inputImageBlock" onClick={()=> {
                                                imageInput.current.click()
                                                handleShowImageManager()
                                            }} 
                                        >
                                            <input 
                                                style={{display:"none"}} 
                                                type="file" accept="image/*" 
                                                onChange={(e) => { setSelectedImages([ ...selectedImages, e.target.files?.item(0) ]) }} 
                                                ref={imageInput}
                                            />
                                            <BsImages style={{marginBottom:"15px"}}size={40}/>
                                            <h2>Upload Image</h2>
                                            {/* <h5>Or Drag and Drop</h5> */}
                                            <h5>Click to upload</h5>
                                        </div>
                                    </Carousel.Item>
                                </Carousel>
                            }
                            
                        </Col>
                    </Col>
                    <Col xs={12} sm={6}>
                        <Col xs={12} style={{paddingTop:"20px"}}>
                            <h5>Title / Qty</h5>
                        </Col>
                        <Col xs={12} className="py-2">
                            <TextField style={{ width: "100%" }} type="text" name="search" id="outlined-basic" label="Title" variant="outlined"
                                value={title}
                                onChange={(e) => { setTitle(e.target.value) }}
                            />
                        </Col>
                        <Col xs={12} className="py-2">
                            <TextField style={{ width: "100%" }} type="number" name="search" id="outlined-basic" label="Quantity" variant="outlined"
                                value={quantity}
                                onChange={(e) => { setQuantity(parseInt(e.target.value)) }}
                            />
                        </Col>
                        <Col xs={12} style={{paddingTop:"20px"}}>
                            <h5>Pricing</h5>
                        </Col>

                        <Col xs={12} className="py-2">
                            <FormControl fullWidth >
                                <PriceInput
                                    id="outlined-adornment-amount"
                                    label="Listing Price"
                                    value={price}
                                    setValue={setPrice}
                                />
                            </FormControl>
                        </Col>
                        
                        <Col xs={12} className="py-2">
                            <FormControl fullWidth component="fieldset" variant="standard">
                                {/* <FormLabel component="legend">Use Sale Price</FormLabel> */}
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox checked={useSalePrice} onChange={()=>{setUseSalePrice(!useSalePrice)}} name="useSalePrice" />
                                        }
                                        label="Use Sale Price"
                                    />
                                </FormGroup>
                            </FormControl>
                        </Col>
                        
                        {useSalePrice &&
                            <Col xs={12} className="py-2">
                                <FormControl fullWidth >
                                    <PriceInput
                                        id="outlined-adornment-amount"
                                        label="Sale Price"
                                        value={salePrice}
                                        setValue={setSalePrice}
                                    />
                                </FormControl>
                            </Col>
                        }
                    </Col>
                    
                    <Col xs={12} sm={6}>
                        <Col xs={12} style={{paddingTop:"20px"}}>
                            <h5>Item Details</h5>
                        </Col>

                        <Col xs={12} className="py-2">
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Condition</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={condition}
                                    label="Condition"
                                    onChange={(e)=>setCondition(e.target.value)}
                                >
                                    <MenuItem value={""}></MenuItem>
                                    <MenuItem value={"New"}>New</MenuItem>
                                    <MenuItem value={"Used"}>Used</MenuItem>
                                </Select>
                            </FormControl>
                        </Col>

                        <Col xs={12} className="py-2">
                            <FormControl sx={{ m: 1, width: "100%", margin:"0px"}} size="small">
                                <Autocomplete
                                    disablePortal
                                    value={brand}
                                    onChange={(event, newValue) => {
                                        setBrand(newValue);
                                    }}
                                    id="combo-box-demo"
                                    options={allBrands}
                                    renderInput={(params) => <TextField {...params} label="Brand" />}
                                />
                            </FormControl>
                        </Col>

                        <Col xs={12} className="py-2">
                            <FormControl sx={{ m: 1, width: "100%", margin:"0px"}} size="small">
                                <Autocomplete
                                    multiple
                                    id="tags-outlined"
                                    options={allCategories}
                                    onChange={(event, newValue) => {
                                        setCategories(newValue);
                                    }}
                                    value = {categories}
                                    filterSelectedOptions
                                    renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Categories"
                                        placeholder="Breeches"
                                    />
                                    )}
                                />
                            </FormControl>
                        </Col>

                        <Col xs={12} className="py-2">
                            <TextField multiline rows={4} maxRow={6} style={{ width: "100%"}} type="text" name="search" id="outlined-basic" label="Description" variant="outlined"
                                value={description}
                                onChange={(e) => { setDescription(e.target.value) }}
                            />
                        </Col>
                    </Col>
                    
                    <Col xs={12} sm={6}>
                        <Col xs={12} className="inputHeader" style={{paddingTop:"20px"}}>
                            <h5>Collection</h5>

                            <CircleButton sm onClick={()=>handleShowCollectionManager()}>
                                <AiOutlinePlus/>
                            </CircleButton>

                        </Col>

                        {/* Wrapper Query for Collections */}

                        <Col xs={12} className="py-2 inut">
                            {/* If no Collection */}
                            <div className="addCollectionButton">
                                
                            </div>
                        </Col>

                        {collections.length == 0 ? 
                        
                        <p>You have no collections.<br/>Create your first collection!</p>
                        :
                            <Col xs={12} className="py-2">
                                <FormControl sx={{ m: 1, width: "100%", margin:"0px"}} size="small">
                                    <Autocomplete
                                        multiple
                                        id="tags-outlined"
                                        options={collections}
                                        getOptionLabel={(c) => c.name}
                                        onChange={(event, newValue) => {
                                            setSelectedCollections(newValue);
                                        }}
                                        value = {selectedCollections}
                                        filterSelectedOptions
                                        renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Collections"
                                            placeholder=""
                                        />
                                        )}
                                    />
                                </FormControl>
                            </Col>
                        }
                    </Col>
                </Row>

                <Row style={{paddingTop:"20px"}}>
                    <Col xs={12} style={{paddingTop:"40px"}}>
                        <Button variant2 onClick={sell} isProcessing={isProcessing} setIsProcessing={setIsProcessing} >
                            {isProcessing ? <CircularProgress /> : "List Item"}
                        </Button>
                    </Col>
                    <Col xs={12} style={{paddingTop:"0px"}}>
                        <Button variantOutlined onClick={sell} isProcessing={isProcessing} setIsProcessing={setIsProcessing} >
                            {isProcessing ? <CircularProgress /> : "Save Draft"}
                        </Button>
                    </Col>
                    <Col xs={12} style={{paddingBottom:"40px"}}>
                        <Button variantRed onClick={sell} isProcessing={isProcessing} setIsProcessing={setIsProcessing} >
                            {isProcessing ? <CircularProgress /> : "Discard"}
                        </Button>
                    </Col>

                </Row>
            </Container>
            

            {/* Image Manager */}
            <Offcanvas show={showImageManager} onHide={()=>handleCloseImageManager()} placement="end">
                <Offcanvas.Header closeButton>
                {/* <Offcanvas.Title>Offcanvas</Offcanvas.Title> */}
                <Link to="/" onClick={()=>handleCloseImageManager()}>
                    <img style={{width:"100px"}} src="/TACKSWAPLOGO_Black.png"/>
                </Link>
                </Offcanvas.Header>
                <Offcanvas.Body className="imageManagerBody">
                    <h4 style={{marginBottom:"20px"}}>Image Manager</h4>
                    <Row>
                        <DragDropContext onDragEnd={reorderImages}>
                            <Droppable droppableId="droppable">

                                
                                {(provided, snapshot) => (
                                    
                                    <Row
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                    >
                                    {selectedImages.map((item, index) => (
                                        <Draggable key={item.name} draggableId={item.name} index={index}>
                                        {(provided, snapshot) => (
                                            
                                                <Col xs={6} className="imageWrapper py-2">
                                                    <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                            >
                                                    <img
                                                        className="image"
                                                        src={URL.createObjectURL(item)}
                                                    />
                                                    <CircleButton variant className="deleteImageButton" sm 
                                                        onClick={() => {
                                                            setSelectedImages(selectedImages.filter((i) => i != item))
                                                        }}
                                                    >
                                                        <BsTrash/>
                                                    </CircleButton>
                                                    </div>
                                                </Col>
                                            
                                        )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                    </Row>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </Row>
                    <Row className="bottomNav">
                        <Col xs={4} className="py-2">
                            <Button onClick={()=> imageInput.current.click()}>
                                <AiOutlinePlus/>
                            </Button>
                        </Col>
                    </Row>
                </Offcanvas.Body>
            </Offcanvas>

            {/* Collection Manager */}
            <Offcanvas show={showCollectionManager} onHide={()=>handleCloseCollectionManager()} placement="end">
                <Offcanvas.Header closeButton>
                {/* <Offcanvas.Title>Offcanvas</Offcanvas.Title> */}
                <Link to="/" onClick={()=>handleCloseImageManager()}>
                    <img style={{width:"100px"}} src="/TACKSWAPLOGO_Black.png"/>
                </Link>
                </Offcanvas.Header>
                <Offcanvas.Body className="imageManagerBody">
                    <h4 style={{marginBottom:"20px"}}>Collection Manager</h4>
                    <Error text={collectionError} />
                    <Row>
                        <Col xs={12} >
                            {newCollectionImage ? 
                                <img
                                    className="d-block w-100 inputImage"
                                    src={URL.createObjectURL(newCollectionImage)}
                                /> : 
                                <div className="inputImageBlock" onClick={()=> {
                                        newCollectionImageInput.current.click()
                                        // handleShowImageManager()
                                    }} 
                                >
                                    <input 
                                        style={{display:"none"}} 
                                        type="file" accept="image/*" 
                                        onChange={(e) => {
                                            const newImage = e.target.files?.item(0)
                                            setNewCollectionImage(newImage)
                                        }} 
                                        ref={newCollectionImageInput}
                                    />
                                    <BsImages style={{marginBottom:"15px"}}size={40}/>
                                    <h2>Upload Image</h2>
                                    {/* <h5>Or Drag and Drop</h5> */}
                                    <h5>Click to upload</h5>
                                </div>
                            }
                        </Col>

                        <Col xs={12} className="py-2">
                            <TextField style={{ width: "100%" }} type="text" name="search" id="outlined-basic" label="Title" variant="outlined"
                                value={newCollectionTitle}
                                onChange={(e) => { setNewCollectionTitle(e.target.value) }}
                            />
                        </Col>
                        <Col xs={12} className="py-2">
                            <TextField multiline rows={4} maxRow={6} style={{ width: "100%"}} type="text" name="search" id="outlined-basic" label="Description" variant="outlined"
                                value={newCollectionDescription}
                                onChange={(e) => { setNewCollectionDescription(e.target.value) }}
                            />
                        </Col>

                        

                    </Row>
                    <Row className="bottomNav">
                        <Col className="py-2">
                            <Button onClick={createCollection} isProcessing={isProcessing} setIsProcessing={setIsProcessing}>
                                {isProcessing ? <CircularProgress disableShrink/> : "Create Collection"}
                            </Button>
                        </Col>
                    </Row>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
}