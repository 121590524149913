import React, {useState} from "react";
import {Link, useLoaderData} from "react-router-dom";
import {Container, Row, Col} from 'react-bootstrap/';
import Offcanvas from 'react-bootstrap/Offcanvas';

// MUI
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import {BsFilter} from 'react-icons/bs';
import {allBrands} from '../Components/AllBrands';
import { Button } from "../Components/Button";
import { Grid } from '../Components/Grid';
import { ProductTile } from '../Components/ProductTile';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { PropaneSharp } from "@mui/icons-material";

export default function CategoriesView(){

    const categoryProducts =  useLoaderData();  

    var [showFilter, setShowFilter] = useState(false);

    const handleCloseFilter = () => {setShowFilter(false)};
    const handleShowFilter = () => {setShowFilter(true)};

    return(
        <>
            <Container>
                <Row>
                    <Col className="headerRow">
                        <h1>Category Name</h1>
                        <button onClick={()=>handleShowFilter()} className="smallButton">Filter <BsFilter/></button>
                    </Col>
                </Row>
                <Row className="py-4">
                    <Col>
                        <Grid style={{padding:"10px 15px 25px"}}>
                            {categoryProducts.map ((product) => (
                                <Col xs={6} md={3} className="px-1">
                                    <ProductTile
                                        product = {product}
                                        isGrid={true}
                                    />
                                </Col>
                            ))}
                        </Grid>
                    </Col>
                </Row>
            </Container>

            <Offcanvas show={showFilter} onHide={handleCloseFilter} placement="end">
                <Offcanvas.Header closeButton>
                {/* <Offcanvas.Title>Offcanvas</Offcanvas.Title> */}
                <Link to="/" onClick={()=>handleCloseFilter()}>
                    <img style={{width:"100px"}} src="/TACKSWAPLOGO_Black.png"/>
                </Link>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <FilterView
                        handleClose={handleCloseFilter}
                    />
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
}
    
function FilterView(props){
    const conditionOptions = ["All", "New", "Pre-Owned"]
    const [condition, setCondition] = useState("All");

    const conditionHandler = (event) => {
        setCondition(event.target.value);
    };

    const brandOptions = allBrands
    const [brand, setBrand] = useState("All");

    // const brandHandler = (event) => {
    //     setBrand(event.target.value);
    // };

    const submit = () => {
        // Handle Submit Code Here ORRRR pass this to higher level view and handle there?

    }
    

    return(
        <div className="filterBody">
            <h2>Filter</h2>
            <div className="filterOptions">
                <FormControl sx={{ m: 1, width: "95%" }} size="small">
                    <InputLabel id="demo-select-small-label">Condition</InputLabel>
                    <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        value={condition}
                        label="Condition"
                        onChange={conditionHandler}
                    >
                        {conditionOptions.map ((condition) => (
                            <MenuItem value={condition}>{condition}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl sx={{ m: 1, width: "95%" }} size="small">
                    <Autocomplete
                        // sx={{ m: 1, width: "95%" }}
                        disablePortal
                        value={brand}
                        onChange={(event, newValue) => {
                            setBrand(newValue);
                        }}
                        id="combo-box-demo"
                        options={allBrands}
                        renderInput={(params) => <TextField {...params} label="Brand" />}
                    />
                </FormControl>
            </div>
            <Button onClick={props.handleClose} className="submitButton">Apply Filter</Button>
        </div>

    );
}



