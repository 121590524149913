

export function sellerLoader(sellerID){
    const seller = {
        id:"1627192",
        name: "Bob's Tack",
        itemsSoldCount: 279,
        followers: 295,
        aboutDescription: "Welcome, this is our tack shop, we sell tack stuff.",
        profileImg: "/TwoToned-Navy-Main.jpg",
        reviews: {
            average: 4.75,
            count: 279
        },
        featured:{
            products:[ "7d597150-d5c2-4073-a733-c0e70a4c6a62", "7d597150-d5c2-4073-a733-c0e70a4c6a62", "7d597150-d5c2-4073-a733-c0e70a4c6a62", "7d597150-d5c2-4073-a733-c0e70a4c6a62", "7d597150-d5c2-4073-a733-c0e70a4c6a62", "7d597150-d5c2-4073-a733-c0e70a4c6a62"],
            collections: ["col1232312", "col1232312", "col1232312", "col1232312", "col1232312", "col1232312"]
        },
        products:[ "7d597150-d5c2-4073-a733-c0e70a4c6a62", "7d597150-d5c2-4073-a733-c0e70a4c6a62", "7d597150-d5c2-4073-a733-c0e70a4c6a62", "7d597150-d5c2-4073-a733-c0e70a4c6a62", "7d597150-d5c2-4073-a733-c0e70a4c6a62", "7d597150-d5c2-4073-a733-c0e70a4c6a62", "7d597150-d5c2-4073-a733-c0e70a4c6a62", "7d597150-d5c2-4073-a733-c0e70a4c6a62","7d597150-d5c2-4073-a733-c0e70a4c6a62" ,"7d597150-d5c2-4073-a733-c0e70a4c6a62","7d597150-d5c2-4073-a733-c0e70a4c6a62","7d597150-d5c2-4073-a733-c0e70a4c6a62","7d597150-d5c2-4073-a733-c0e70a4c6a62","7d597150-d5c2-4073-a733-c0e70a4c6a62","7d597150-d5c2-4073-a733-c0e70a4c6a62"],
        collections:["col1232312", "col1232312", "col1232312", "col1232312", "col1232312", "col1232312", "col1232312", "col1232312", "col1232312", "col1232312", "col1232312"]

    } // <-- will be turned into a query for product

    console.log("getting info for seller:" + sellerID);

    return seller
}