import { graphqlOrRedirect } from "../Helpers";
import { API, graphqlOperation } from "aws-amplify";
import { useEffect, useState, useCallback } from "react";

export const UpdateTypes = Object.freeze({
    CREATE: "CREATE",
    DELETE: "DELETE",
    UPDATE: "UPDATE"
})

export function SubscribeWrapper({ queryName, query, onUpdate, variables }) {

    const [subscriptions, setSubscriptions] = useState({
        onCreate: API.graphql(graphqlOperation(`
            subscription Create${queryName} {
                onCreate${query}
            }
        `, variables)),
        onDelete: API.graphql(graphqlOperation(`
            subscription Delete${queryName} {
                onDelete${query}
            }
        `, variables)),
        onUpdate: API.graphql(graphqlOperation(`
            subscription Update${queryName} {
                onUpdate${query}
            }
        `, variables))
    })

    useEffect(() => {
        const h1 = subscriptions.onCreate.subscribe({
            next: ({ value }) => {
                onUpdate({ type: UpdateTypes.CREATE, value: value })
            },
            error: (error) => {
                console.error(error)
            }
        })
        const h2 = subscriptions.onDelete.subscribe({
            next: ({ value }) => {
                onUpdate({ type: UpdateTypes.DELETE, value: value })
            },
            error: (error) => {
                console.error(error)
            }
        })
        const h3 = subscriptions.onUpdate.subscribe({
            next: ({ value }) => {
                onUpdate({ type: UpdateTypes.UPDATE, value: value })
            },
            error: (error) => {
                console.error(error)
            }
        })
        return () => {
            h1.unsubscribe()
            h2.unsubscribe()
            h3.unsubscribe()
            console.log("Unsubscribed")
        }
    }, [subscriptions, onUpdate])

    return (<></>)
}