import React from 'react';
import { Link} from "react-router-dom";
import {Col, Row, Container} from "react-bootstrap";

import ReactTimeAgo from 'react-time-ago';

export function MessageBlob(props){
    return(
        <div xs={12} className={`messageWrapper ${props.sent && "sent"} ${props.received && "received"}`}>
            <div className="messageWrap">
                <div className={`message ${props.sent && "sent"} ${props.received && "received"}`}>
                    <p>{props.content}</p>
                </div>
                <ReactTimeAgo date={props.time} component={({ children }) => (<p class="time">{children}</p>)}/>
            </div>
        </div>
    );
}