import React from 'react';
import {Container, Row, Col} from 'react-bootstrap/';
import { Scroller } from '../Components/Scroller';
import { CategoriesButton } from '../Components/CategoriesButton';
import { Link} from "react-router-dom";
import Carousel from 'react-bootstrap/Carousel';
import {useLoaderData} from "react-router-dom";
import {AiOutlineHeart, AiOutlineMail, AiOutlineThunderbolt, AiFillStar} from "react-icons/ai";
import {FiShare} from "react-icons/fi";
import { CircleButton } from '../Components/CircleButton';
import { Button } from '../Components/Button';
import { Grid } from '../Components/Grid';
import { ProductTile } from '../Components/ProductTile';
import { CollectionTile } from '../Components/CollectionTile';


export default function SellerView(){

    const seller =  useLoaderData();

    return(
        <Container>
            <Row className="sellerInfoSection">
                <Col xs={0} sm="auto" className="sellerImgSection d-none d-sm-block">
                    <div className="sellerImg">
                        <img src={seller.profileImg}/>
                    </div>
                </Col>
                <Col xs={12} sm={7} lg={9}>
                    <div className="sellerDetailsSection sellerImgSection">
                        <div className="sellerImg d-block d-sm-none">
                            <img src={seller.profileImg}/>
                        </div>

                        <h1 className="sellerTitle">{seller.name}</h1>

                        <p>{seller.itemsSoldCount} Items Sold</p>
                        <p>{seller.reviews.average} <AiFillStar/></p>

                        <Row>
                            <Col style={{textAlign:"center"}}>
                                <CircleButton textLabel="Favorite"><AiOutlineHeart/></CircleButton>
                            </Col>
                            <Col style={{textAlign:"center"}}>
                                <CircleButton textLabel="Contact"><AiOutlineMail/></CircleButton>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h2>Featured Products</h2>

                    <Scroller style={{paddingTop:"10px", paddingBottom:"25px"}}>
                        {seller.featured.products.map ((product) => (
                            <Col xs={5} md={3} className="px-1">
                                <ProductTile
                                    product = {product}
                                />
                            </Col>
                        ))}
                    </Scroller>
                </Col>
            </Row>

            <Row>
                <Col>
                    <h2>Featured Collections</h2>
                    
                    <Scroller style={{paddingTop:"10px", paddingBottom:"25px"}}>
                        {seller.featured.collections.map ((collection) => (
                            <Col xs={5} md={3} className="px-1">
                                <CollectionTile
                                    collection = {collection}
                                />
                            </Col>
                        ))}
                    </Scroller>
                </Col>
            </Row>
        </Container>
    );
}