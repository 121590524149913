import React, { useState } from 'react';
import '@aws-amplify/ui-react/styles.css';
import { Row, Col } from 'react-bootstrap';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import { Button } from '../Components/Button';
import { Link, Navigate, useParams } from "react-router-dom";
import { Auth } from 'aws-amplify';
import { CircularProgress } from '@mui/material';

import { Error } from '../Components/Error';


export default function UserAuth() {

    const AuthState = Object.freeze({
        signIn: "signIn",
        signUp: "signUp",
        confirmSignUp: "confirmSignUp",
        authenticated: "authenticated"
    })

    const params = useParams()

    const [isProcessing, setIsProcessing] = useState(false)
    const [error, setError] = useState(null)

    const [authState, innerSetAuthState] = useState(AuthState.signIn)
    function setAuthState(state) {
        innerSetAuthState(state)
        setError(null)
    }

    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [passwordConfirmation, setPasswordConfirmation] = useState("")
    const [confirmationCode, setConfirmationCode] = useState("")

    const numberRegex = /^[0-9]{0,6}$/

    function displayError(error) {
        if (!error.endsWith(".")) {
            error += "."
        }
        setError(error)
    }

    function handleError(error) {
        console.error(error)
        switch (error.name) {
            case "InvalidParameterException":
                if (error.message === "Custom auth lambda trigger is not configured for the user pool.") {
                    displayError("Password cannot be empty.")
                    break;
                } else if (error.message.includes("Value at 'password' failed to satisfy constraint")) {
                    displayError("Password must be at least 8 characters long and cannot begin or end with a space.")
                    break;
                }
                displayError(error.message)
                break;
            case "UserNotFoundException":
                displayError("Incorrect username or password.")
                break;
            default:
                displayError(error.message)
        }
    }

    async function signUp() {
        if (name.length === 0) {
            displayError("Name cannot be empty.")
            return 
        }
        if (password !== passwordConfirmation) {
            displayError("Passwords do not match.")
            return
        }
        if (password.length < 8) {
            displayError("Password must be at least 8 characters long.")
            return
        }
        try {
            const user = await Auth.signUp({
                username: name,
                password: password,
                attributes: {
                    email: email,
                }
            })
            setAuthState(AuthState.confirmSignUp)
        } catch(error) {
            handleError(error)
        }
    }

    async function signIn() {
        try {
            const user = await Auth.signIn(name, password)
            setAuthState(AuthState.authenticated)
        } catch(error) {
            if (error.name === "UserNotConfirmedException") {
                setAuthState(AuthState.confirmSignUp)
                return
            }
            handleError(error)
        }
    }

    async function confirmSignUp() {
        try {
            const user = await Auth.confirmSignUp(name, confirmationCode)
            await Auth.signIn(name, password)
            setAuthState(AuthState.authenticated)
        } catch(error) {
            handleError(error)
        }
    }

    async function resendVerficationCode() {
        try {
            const user = await Auth.resendSignUp(email)
        } catch(error) {
            handleError(error)
        }
    }

    return (
        <Row>
            <Col sm={5} className="signInLeftBG d-none d-sm-block"
                style={{
                    backgroundImage: `url(${process.env.PUBLIC_URL + '/signInBG.jpg'})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    height: "100vh"
                }}
            >
            </Col>
            {(authState === AuthState.signIn) && (
                <Col xs={12} sm={7} className="signInRight">
                    <div className="signInBox">
                        <Link to="/"><img style={{ width: "180px" }} src="/TACKSWAPLOGO_Black.png" alt="" /></Link>
                        <Error text={error} />
                        <TextField style={{ width: "100%" }} type="text" name="search" id="outlined-basic" label="Username/Email" variant="outlined"
                            value={name}
                            onChange={(e) => { setName(e.target.value) }}
                        />
                        <TextField style={{ width: "100%" }} type="password" name="search" id="outlined-basic" label="Password" variant="outlined"
                            value={password}
                            onChange={(e) => { setPassword(e.target.value) }}
                        />
                        <Button onClick={signIn} isProcessing={isProcessing} setIsProcessing={setIsProcessing} >
                            {isProcessing ? <CircularProgress /> : "Sign In"}
                        </Button>
                        <p>Don't Have an Account? <span onClick={() => setAuthState(AuthState.signUp)} style={{ textDecoration: "underline" }}>Sign Up</span></p>
                    </div>
                </Col>
            )}
            {(authState === AuthState.signUp) && (
                <Col xs={12} sm={7} className="signUpRight">
                    <div className="signInBox">
                        <Link to="/"><img style={{ width: "180px" }} src="/TACKSWAPLOGO_Black.png" alt="" /></Link>
                        <Error text={error} />
                        <TextField style={{ width: "100%" }} type="text" name="username" id="outlined-basic" label="Userame" variant="outlined"
                            value={name}
                            onChange={(e) => { setName(e.target.value) }}
                        />
                        <TextField style={{ width: "100%" }} type="email" name="email" id="outlined-basic" label="Email" variant="outlined"
                            value={email}
                            onChange={(e) => { setEmail(e.target.value) }}
                        />
                        <TextField type="password" style={{ width: "100%" }} name="password" id="outlined-basic" label="Password" variant="outlined"
                            value={password}
                            onChange={(e) => { setPassword(e.target.value) }}
                        />
                        <TextField type="password" style={{ width: "100%" }} name="passwordConfirm" id="outlined-basic" label="Password Confirmation" variant="outlined"
                            value={passwordConfirmation}
                            onChange={(e) => { setPasswordConfirmation(e.target.value) }}
                        />
                        <Button onClick={signUp} isProcessing={isProcessing} setIsProcessing={setIsProcessing} >
                            {isProcessing ? <CircularProgress /> : "Sign Up"}
                        </Button>
                        <p>Already have an Account? <span onClick={() => setAuthState(AuthState.signIn)} style={{ textDecoration: "underline" }}>Sign In</span></p>
                    </div>
                </Col>
            )}
            {(authState === AuthState.confirmSignUp) && (
                <Col xs={12} sm={7} className="signInRight">
                    <div className="signInBox">
                        <Link to="/"><img style={{ width: "180px" }} src="/TACKSWAPLOGO_Black.png" alt="" /></Link>
                        <Error text={error} />
                        <TextField style={{ width: "100%" }} type="text" name="search" id="outlined-basic" label="Confirmation Code" variant="outlined"
                            value={confirmationCode}
                            onChange={(e) => {
                                const value = e.target.value
                                if (!numberRegex.test(value)) {
                                    return
                                }
                                setConfirmationCode(value)
                            }}
                        />
                        <Button onClick={confirmSignUp} isProcessing={isProcessing} setIsProcessing={setIsProcessing} >
                            {isProcessing ? <CircularProgress /> : "Confirm"}
                        </Button>
                        <p><span onClick={resendVerficationCode} style={{ textDecoration: "underline" }}>Resend verficiation code</span></p>
                    </div>
                </Col>
            )}
            {(authState === AuthState.authenticated) && (
                <Navigate to={ "/" + (params["*"] ?? "") } />
            )}
        </Row>
    )
}