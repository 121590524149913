import { API } from "aws-amplify";

export async function homeLoader(productId) {

    const exploreProducts = await API.post("tackswapGateway", "/search", {
        body: {
            operation: "explore"
        }
    })

    const mostFavoritedItems = await API.post("tackswapGateway", "/search", {
        body: {
            operation: "get_favorited"
        }
    })

    return {
        exploreProducts: (exploreProducts.hits?.hits ?? []).map((h) => h._source),
        mostFavoritedItems: (mostFavoritedItems.hits?.hits ?? []).map((h) => h._source),
    }
}