import { create } from "@mui/material/styles/createTransitions"
import { API } from "aws-amplify"
import { useState } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import { graphqlOrRedirect } from "../Helpers"


export function RelationWrapper(props) {

    const firstId = props.firstId
    const secondId = props.secondId

    const navigate = useNavigate()
    const location = useLocation()
    const [relationId, setRelationId] = useState(props.relationId ?? null)

    async function createRelation() {
        const relation = await graphqlOrRedirect({
            query: props.createRelationQuery,
            variables: {
                firstId: firstId,
                secondId: secondId
            }
        }, navigate, location)
        if (relation == null) return
        const id = Object.values(relation.data)[0].id
        setRelationId(id)
    }

    async function deleteRelation() {
        const relation = await graphqlOrRedirect({
            query: props.deleteRelationQuery,
            variables: {
                id: relationId
            }
        }, navigate, location)
        if (relation == null) return
        setRelationId(null)
    }

    async function toggleRelation() {
        if (relationId == null) {
            await createRelation()
        } else {
            await deleteRelation()
        }
    }

    return props.render({
        createRelation,
        deleteRelation,
        toggleRelation,
        relationExists: relationId != null
    })
}

export function FavoriteSellerWrapper(props) {
    return (
        <RelationWrapper
            firstId={props.userId}
            secondId={props.sellerId}
            relationId={props.relationId}
            createRelationQuery={`
                mutation FavoriteUser($firstId: ID!, $secondId: ID!) {
                    createFavoriteUser(input: {favoritedBy: $firstId, favoriteId: $secondId}) {
                        id
                    }
                }
            `}
            deleteRelationQuery={`
                mutation UnfavoriteUser($id: ID!) {
                    deleteFavoriteUser(input: {id: $id}) {
                        id
                    }
                }
            `}
            render={props.render}
        />
    )
}

export function FavoriteProductWrapper(props) {
    return (
        <RelationWrapper
            firstId={props.userId}
            secondId={props.productId}
            relationId={props.relationId}
            createRelationQuery={`
                mutation FavoriteProduct($firstId: ID!, $secondId: ID!) {
                    createFavoriteProduct(input: {userId: $firstId, productId: $secondId}) {
                        id
                    }
                }
            `}
            deleteRelationQuery={`
                mutation UnfavoriteProduct($id: ID!) {
                    deleteFavoriteProduct(input: {id: $id}) {
                        id
                    }
                }
            `}
            render={props.render}
        />
    )
}

export function CartProductWrapper(props) {
    return (
        <RelationWrapper
            firstId={props.userId}
            secondId={props.productId}
            relationId={props.relationId}
            createRelationQuery={`
                mutation CartProduct($firstId: ID!, $secondId: ID!) {
                    createCartProduct(input: {userId: $firstId, productId: $secondId}) {
                        id
                    }
                }
            `}
            deleteRelationQuery={`
                mutation UncartProduct($id: ID!) {
                    deleteCartProduct(input: {id: $id}) {
                        id
                    }
                }
            `}
            render={props.render}
        />
    )
}

export function CollectionProductWrapper(props) {
    return (
        <RelationWrapper
            firstId={props.collectionId}
            secondId={props.productId}
            relationId={props.relationId}
            createRelationQuery={`
                mutation AddCollectionProduct($firstId: ID!, $secondId: ID!) {
                    createCollectionProduct(input: {collectionId: $firstId, productId: $secondId}) {
                        id
                    }
                }
            `}
            deleteRelationQuery={`
                mutation DeleteCollectionProduct($id: ID!) {
                    deleteCollectionProduct(input: {id: $id}) {
                        id
                    }
                }
            `}
            render={props.render}
        />
    )
}