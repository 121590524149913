import React, { useState } from 'react';

export function Button(props) {
    var onClick = props.onClick

    if (props.setIsProcessing) {
        onClick = () => {
            props.setIsProcessing(true)
            props.onClick().then(() => {
                props.setIsProcessing(false)
            })
        }
    }
    
    return(
        <button className={`btn ${props.checkout && "checkout"} ${props.variant && "variant"} ${props.variant2 && "variant2"} ${props.variantOutlined && "variantOutlined"} ${props.variantRed && "variantRed"}  ${props.smallText && "sm"}`} onClick={onClick} disabled={props.isProcessing === true}>
            <p>{props.children}</p>
        </button>
    );
}