import React, { useState, useRef, useEffect, useCallback } from "react";
import { Container, Row, Col} from "react-bootstrap";
import {useLoaderData, Link} from "react-router-dom";
import {BiChevronsRight} from 'react-icons/bi';
import {MessageBlob} from "../../Components/MessageBlob.js";
import TextField from '@mui/material/TextField';
import { CircleButton } from "../../Components/CircleButton.js";
import {AiOutlineArrowLeft} from 'react-icons/ai';

import { API } from "aws-amplify";
import { NavBar } from "../../Components/NavBar.js";
import { SubscribeWrapper, UpdateTypes } from "../../Wrappers/SubscribeWrapper.js";

export default function MessageView(){

    async function sendMessage(msg, threadId, userId) {
        const message = await API.graphql({
            query: `
                mutation CreateMessage($content: String!, $sentById: ID!, $threadId: ID!) {
                    createMessage(input: {
                        content: $content
                        threadId: $threadId
                        sentById: $sentById
                    }) {
                        id
                        content
                        createdAt
                        threadId
                        sentBy {
                            id
                            name
                            image
                        }
                    }
                }
            `,
            variables: {
                content: msg,
                threadId: threadId,
                sentById: userId
            }
        })

        setMessage("")
        scrollToLastMessage();
    }

    const { thread, userId } = useLoaderData();
    const otherUser = thread.users.items.filter((u) => u.user.id !== userId)[0].user

    const [messages, setMessages] = useState(thread.messages.items)
    const [message, setMessage] = useState("");

    const messageBody = useRef(null);

    const scrollToLastMessage = () => {
        setTimeout(() => {
            const lastChildElement = messageBody.current?.lastElementChild;
            lastChildElement?.scrollIntoView({ behavior: 'smooth' });
        }, 220);

        // const msgBody = messageBody.current;
        // messageBody.current.scrollTo(0, messageBody.scrollHeight);
    };

    const updateMessages = useCallback(({ type, value }) => {
        if (type === UpdateTypes.CREATE) {
            setMessages(messages => [...messages, value.data.onCreateMessage])
        }
    }, [])
    
    useEffect(() => {
        scrollToLastMessage();
    }, []);

    return(
        <div id="messagePageWrapper">
            <NavBar
                showProfile={true}
                showSidebar={true}
                showSearchIcon={true}
            />
            <Container className="messageMain">

                <div className="messageHeader">
                    <Link to="/profile/messages/">
                        <CircleButton sm className="backButton">
                            <AiOutlineArrowLeft/>
                        </CircleButton>
                    </Link>
                    <div className="profileImgWrapper">
                        <img src={otherUser.image ?? "/profilePlaceholder.jpeg"}/> {/* Need Code to pull in Img */}
                    </div>
                    <h5>{otherUser.name}</h5>
                </div>

                <SubscribeWrapper
                    queryName="MessageSubscription($filter: ModelSubscriptionMessageFilterInput)"
                    query={
                    `Message(filter: $filter) {
                        createdAt
                        content
                        id
                        sentBy {
                            id
                            name
                            image
                        }
                    }`}
                    variables={{ filter: { threadId: { eq: thread.id}}}}
                    onUpdate={updateMessages}
                />

                <div className="messagesBody" ref={messageBody}>
                    {messages.map((message) => {
                        const date = new Date(message.createdAt)
                        return (
                            <MessageBlob
                                sent={message.sentBy.id === userId}
                                received={message.sentBy.id !== userId}
                                content={message.content}
                                time={date}
                            />
                        )
                    })}

                </div>

                <div className="messageInputWrapper">
                    <div className="messageInput">

                        <TextField fullWidth multiline maxRows={4} label="message" id="fullWidth" value={message} onChange={(e) => { setMessage(e.target.value) }} />

                        <CircleButton sm className="sendMessage" onClick={(e) => { sendMessage(message, thread.id, userId) }}>
                            <BiChevronsRight style={{fontSize:"1.4rem"}}/>
                        </CircleButton>
                    </div>
                </div>
                
            </Container>
        </div>
    );
}