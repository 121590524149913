import { getImageListItemBarUtilityClass } from '@mui/material';
import React, {useEffect} from 'react';
import { Link } from "react-router-dom";
import { ProductPrice } from './Formatter';

function getImage(product) {
    console.log(product)
    const placeholder = "/TwoToned-Navy-Main.jpg";
    const images = product.images;
    const mainImage = product.mainImage;
    if (images == null || mainImage == null || images.length <= mainImage) {
        return placeholder
    }
    return images[mainImage];
}

export function ProductTile(props){
    return(

        // <Col xs={5} sm>
        <Link to={`/product/${props.product.id}`}>
            <div className={`productTile ${props.isGrid && "isGrid"}`}>
                <div className="imgSection">
                    <img src={getImage(props.product)}/> 
                    {/* WIlliam, TODO => move getImage to a helper function that lives somewhere else */}
                </div>
                <div className="productTextSection">
                    <h5 className="brand">{props.product.brand}</h5>
                    <h4 className="title">{props.product.title}</h4> 
                    <h5 className="condition">{props.product.condition}</h5>
                    
                    <ProductPrice product={props.product}/>
                </div>
            </div>
        </Link>
        // </Col>

    );
}