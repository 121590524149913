import { API } from "aws-amplify";
import { getUserOrNull } from "../Helpers";
import { redirect } from "react-router-dom";
import { Api } from "@mui/icons-material";


const threadsQuery = `
query GetThreads {
    listThreads {
        items {
            createdAt
            id
            messages(limit: 1, sortDirection: DESC) {
                items {
                    createdAt
                    content
                }
            }
            users {
                items {
                    user {
                        id
                        name
                        image
                    }
                }
            }
        }
    }
}
`

export async function messagesLoader(productID) {
    const user = await getUserOrNull()
    if (user === null) {
        return redirect("/auth/favorited")
    }
    const threads = await API.graphql({
        query: threadsQuery,
    })
    console.log(user.attributes.sub)
    console.log(threads)
    return {
        threads: threads.data.listThreads.items,
        userId: user.attributes.sub,
        userName: user.username
    }
}