import { API } from "aws-amplify";
import { redirect } from "react-router-dom";
import { getUserOrNull, productTileFragment } from "../Helpers";

const collectionQuery = `
query GetUserCollections($userId: ID!) {
    getUser(id: $userId) {
        collections {
            items {
                id
                name
                image
                products {
                    items {
                        ${productTileFragment}
                    }
                }
            }
        }
    }
}`

export async function collectionLoader() {
    const user = await getUserOrNull()
    if (user == null) {
        return redirect("/auth/profile")
    }

    const id = user.attributes.sub
    const result = await API.graphql({
        query: collectionQuery,
        variables: { userId: id }
    })
    return result.data.getUser.collections.items

    // const collection = {
    //     id: "7d597150-d5c2-4073-a733-c0e70a4c6a62",
    //     title: "Ivy Full Seat Breeches",
    //     description: "Fancy breeches - only worn once for a competition but they did not fit me right. Otherwise, a lovely breathable product.",
    //     mainImg: "/TwoToned-Navy-Main.jpg",
    //     imgs: ["/TwoToned-Navy-Main.jpg", "/TwoToned-Navy-Main.jpg", "/TwoToned-Navy-Main.jpg", "/TwoToned-Navy-Main.jpg", "/TwoToned-Navy-Main.jpg",],
    //     shippingOptions: {
    //         price:"7.00",
    //     },
    //     returnOptions: {
    //         accepted:true,
    //     },
    //     seller: {
    //         id:"1627192",
    //         name: "Bob's Tack",
    //         itemsSoldCount: 279,
    //         followers: 295,
    //         aboutDescription: "Welcome, this is our tack shop, we sell tack stuff.",
    //         rating: {
    //             average: 4.75,
    //             count: 279
    //         }
    //     }
    // } // <-- will be turned into a query for collection

    // console.log("getting info for collection:" + collectionID);

    // return collection
}